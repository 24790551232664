<template>
  <div class="orders">
    <div class="orders-header">
      <div style="font-size: larger;">
        <b>Previous Orders</b>
      </div>
    </div>
    <div v-if="orders.length > 0" class="previous-orders">
      <router-link v-for="order in orders" :key="order.order_id" :to="{ name: 'order', params: { id: order.order_id } }" class="order">
        <span>{{ order.order_id }}</span>
        <span :title="new Date(order.time_created * 1000)">{{ new Date(order.time_created * 1000).toLocaleString() }}</span>
      </router-link>
    </div>
    <div v-if="orders.length == 0">
      No orders found.
    </div>
  </div>
</template>

<script>
import utils from '@/utils.js'

export default {
  data() {
    return {
      orders: [],
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.orders = data.result;
  //   });
  // },
  mounted() {
    this.getOrdersFromBrowserCache();
  },
  methods: {
    getOrdersFromBrowserCache() {
      var saved_orders = localStorage.getItem('chikun-market-orders');
      var orders_parsed = null;
      try {
        orders_parsed = JSON.parse(saved_orders);
      } catch (ex) {
        console.error(ex);
        return;
      }

      if (orders_parsed != null) {
        this.orders = orders_parsed;
      }
    },
    ...utils
  },
};
</script>

<style scoped>
.orders {
  display: flex;
  flex-direction: column;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  max-width: 920px;
  margin: auto;
  padding: 2rem;
}

.orders-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.previous-orders {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: -webkit-fill-available;
}

.previous-orders > *:not(:last-child) {
  margin-bottom: 1rem;
}

.order {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.order:hover {
  cursor: pointer;
  color: inherit;
  background-color: #222;
}
</style>