<template>
  <div class="address"></div>
  <h1>{{ (address === wallet_address) ? "My Wallet" : address }}</h1>
  <div class="tabs-wrapper hide-scroll">
    <ul class="tabs"><li :class="{ 'active': tab == 0 }" @click="showTab(0)">Holdings</li><li :class="{ 'active': tab == 1 }" @click="showTab(1)">Activity</li></ul>
  </div>
  <div v-if="tab == 0">
    <div style="margin-bottom: 1rem;">
      <h3>Runes</h3>
      <div class="table-container">
        <RunesHoldingsComponent v-if="runes.length > 0" :items="runes" :is_owner="address === wallet_address" style="margin-bottom: 1rem;"/>
        <div v-if="runes.length == 0" class="address-inscriptions">
          No runes found
        </div>
        <div v-if="error_message" class="address-inscriptions" style="color: tomato;">
          {{ error_message }}
        </div>
      </div>
      <h3 v-if="ltc20.length > 0">LTC-20</h3>
      <div v-if="ltc20.length > 0" class="table-container">
        <Ltc20HoldingsComponent :items="ltc20" style="margin-bottom: 1rem;"/>
      </div>
      <div class="address-inscriptions">
        <div :class="{ 'inscription-container': true, 'selected': isInscriptionSelected(item.inscription_id) }" v-for="item in ltc20_inscriptions" :key="item.inscription_id" @click="item.listed ? inscriptionSelected(item.inscription_id, 2, item.ltc20_ticker) : inscriptionSelected(item.inscription_id, 1, item.ltc20_ticker)">
          <div v-if="item.listed">
            <div class="listed-price">
              <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(item.listed.value).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
            </div>
          </div>
          <div>
            <div v-if="item.ltc20_ticker">{{ item.ltc20_ticker }}</div>
            <div class="inscription-token">
              <div v-if="item.ltc20_amount">{{ item.ltc20_amount }}</div>
            </div>
          </div>
          <router-link :to="{ name: 'inscription', params: { id: item.inscription_id }}" style="padding-top: 0.5rem;" @click="linkClicked">#{{ item.inscription_number }}</router-link>
          <div style="display: flex; gap: 10px; width: 100%;">
          <button v-if="item.listed" :class="{'default-button': true, 'edit-button': address === wallet_address }" style="margin-top: 0.5rem;" @click="address === wallet_address ? editListing(item.inscription_id) : buyItem(item.inscription_id, item.listed.value)">{{ (address === wallet_address) ? "Edit" : "Buy" }}</button>
          <button v-if="!item.listed && address === wallet_address" class="default-button list-button" style="margin-top: 0.5rem;">List</button>
          <button v-if="address === wallet_address" class="transfer-button" style="margin-top: 0.5rem;" @click.stop.prevent="sendItem(item.inscription_id, { token: true })">
            <font-awesome-icon :icon="['fas', 'paper-plane']" />
          </button>
        </div>
        </div>
      </div>
    </div>
    <h3>Inscriptions</h3>
    <div v-if="inscriptions.length == 0" class="address-inscriptions">
      No inscriptions found
    </div>
    <div v-if="inscriptions.length > 0" class="address-inscriptions">
      <div :class="{ 'inscription-container': true, 'selected': isInscriptionSelected(item.inscription_id) }" v-for="item in inscriptions" :key="item.inscription_id" @click="item.listed ? inscriptionSelected(item.inscription_id, 2) : inscriptionSelected(item.inscription_id, 1)">
        <div>
          <img v-if="!item.content && isImage(item.contentType)" class="inscription-preview" :src="`https://ordinalslite.com/content/${item.inscription_id}`" :alt="'Inscription ' + item.inscription_number"/>
          <div v-if="!item.content && isIframe(item.contentType)" class="inscription-img inscription-iframe">
            <iframe scrolling="no" loading="lazy" style="transform: scale(0.25); transform-origin: 0 0; width: 400%; height: 400%; border: 0;" :src="'https://ordinalslite.com/preview/' + item.inscription_id"></iframe>
          </div>
          <div v-if="item.content" class="inscription-text">
            <div>{{ item.content }}</div>
          </div>
        </div>
        <div v-if="item.listed">
          <div class="listed-price">
            <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(item.listed.value) }}
          </div>
        </div>
        <router-link :to="{ name: 'inscription', params: { id: item.inscription_id }}" style="padding-top: 0.5rem;" @click="linkClicked">#{{ item.inscription_number }}</router-link>
        <div style="display: flex; gap: 10px; width: 100%;">
            <button v-if="item.listed" :class="{'default-button': true, 'edit-button': address === wallet_address }" style="margin-top: 0.5rem;" @click.stop.prevent="address === wallet_address ? editListing(item.inscription_id) : buyItem(item.inscription_id, item.listed.value)">
              <font-awesome-icon v-if="address === wallet_address" :icon="['fas', 'tag']"/>
              {{ (address === wallet_address) ? "Edit" : "Buy" }}
            </button>
          <button v-if="!item.listed && address === wallet_address" class="default-button list-button" style="margin-top: 0.5rem;" @click.stop.prevent="listItem(item.inscription_id)">
            <font-awesome-icon :icon="['fas', 'tag']"/>  
            List
          </button>
          <button v-if="address === wallet_address" class="transfer-button" style="margin-top: 0.5rem;" @click.stop.prevent="sendItem(item.inscription_id)">
            <font-awesome-icon :icon="['fas', 'paper-plane']" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="tab == 1" class="table-container">
    <ActivityTableComponent :items="activity" :address="address"/>
    <InfiniteScrollComponent v-if="allowLoadActivity" @infinite="loadActivity" />
  </div>
</template>

<script>
import { inject } from 'vue'
import config from '@/config.js'
import utils from '@/utils';

import ActivityTableComponent from '@/components/ActivityTableComponent.vue'
import InfiniteScrollComponent from '@/components/InfiniteScrollComponent.vue'

import Ltc20HoldingsComponent from '@/components/Ltc20HoldingsComponent.vue';
import RunesHoldingsComponent from '@/components/RunesHoldingsComponent.vue';

export default {
  data() {
    return {
      tab: 0,
      address: "ltc1",
      wallet_provider: this.$walletProvider,
      wallet_address: inject('wallet_address', false),
      balance: 0,
      inscription_balance: 0,
      inscriptions: [],
      ltc20: [],
      runes: [],
      ltc20_inscriptions: [],
      activity: [],
      selected_inscriptions: [],
      selection_mode: 0,
      selection_token: null,
      allowLoadActivity: true,
      page_number_activity: 1,
      error_message: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    fetch(`${config.apiUrl}/address/collections?address=${to.params.address}`)
      .then(d => d.json())
      .then(data => {
        // Pass the fetched data to the next callback
        var transfer_inscriptions = [];
        for (const transfer of data.result.ltc20) {
          transfer_inscriptions.push(...transfer.transferInscriptions);
        }

        next(vm => {
          vm.inscriptions = data.result.inscriptions;
          vm.ltc20 = data.result.ltc20;
          vm.ltc20_inscriptions = transfer_inscriptions;
          vm.address = to.params.address;
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        next(from.path);
      })
  },
  beforeRouteLeave(to, from, next) {
    this.emitter.emit("select-bar-close");
    next();
  },
  components: {
    ActivityTableComponent,
    InfiniteScrollComponent,
    Ltc20HoldingsComponent,
    RunesHoldingsComponent
  },
  watch: {
    '$route': function(to, from) {
      if (to.name === 'address' && to.params.address !== from.params.address) {
        this.fetch_address_holdings(to.params.address, from);
      }
    }
  },
  methods: {
    fetch_address_holdings(address, from, next = null) {
      fetch(`${config.apiUrl}/address/collections?address=${address}`)
      .then(d => d.json())
      .then(data => {
        // Pass the fetched data to the next callback
        this.inscriptions = data.result.inscriptions;
        this.ltc20 = data.result.ltc20;
        var transfer_inscriptions = [];
        for (const transfer of data.result.ltc20) {
          transfer_inscriptions.push(...transfer.transferInscriptions);
        }
        this.ltc20_inscriptions = transfer_inscriptions;
        this.address = address;
        this.loadRunes();
        if (next) {
          next(vm => {
            Object.assign(vm, this.$data);
          });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.$router.replace(from.path);
      })
    },
    showTab(number) {
      this.tab = number;
      if (this.tab == 1) {
        fetch(`${config.apiUrl}/market/events?type=-1&address=${this.address}`)
          .then(d => d.json())
          .then(data => {
            this.page_number_activity = 1;
            this.allowLoadActivity = true;
            this.activity = data.result;
          });
      }
    },
    linkClicked() {
      event.stopPropagation();
    },
    inscriptionSelected(inscription_id, mode = 0, token = null) {
      if (this.wallet_address !== this.address) {
        return;
      }

      console.log(this.selection_mode, mode, token)
      if ((this.selection_mode != 0 && this.selection_mode != mode) || (this.selection_token == null && token != null && this.selected_inscriptions.length > 0)) {
        return;
      }

      // deselect
      if ((this.selection_token != null && token == null) || (this.selection_token != null && token != null)) {
        this.selected_inscriptions = [];
        this.selection_mode = 0;
        this.selection_token = null;
        this.emitter.emit("select-bar-open", { inscription_ids: this.selected_inscriptions, bulk_mode: true, updating: mode == 2, token: this.selection_token });
        return;
      }

      const index = this.selected_inscriptions.indexOf(inscription_id);
      if (index === -1) {
        this.selected_inscriptions.push(inscription_id);
        this.selection_mode = mode;
        this.selection_token = token;
      } else {
        this.selected_inscriptions.splice(index, 1);
        if (this.selected_inscriptions.length == 0) {
          this.selection_mode = 0;
          this.selection_token = null;
        }
      }
      this.emitter.emit("select-bar-open", { inscription_ids: this.selected_inscriptions, bulk_mode: true, updating: mode == 2, token: this.selection_token });
    },
    isInscriptionSelected(inscription_id) {
      return this.selected_inscriptions.includes(inscription_id);
    },
    async loadActivity() {
      if (!this.allowLoadActivity || this.activity.length < 20) {
        return;
      }

      console.log("Activity")

      var events_paged = await fetch(`${config.apiUrl}/market/events?type=-1&address=${this.address}&page=${++this.page_number_activity}`)
        .then(d => d.json());
      
      if (events_paged.status == 1) {
        if (events_paged.result.length < 20) {
          this.allowLoadListings = false;
        }

        this.activity.push(...events_paged.result);
      }
    },
    loadRunes() {
      this.error_message = null;
      this.wallet_provider.getRunicBalances(this.address)
        .then(r => this.runes = r)
        .catch(e => {
          this.error_message = e;
        });
    },
    listItem(itemId, price) {
      console.log(`Listing item with ID ${itemId} for ${price}`);
      this.emitter.emit("modal-list-open", { inscriptions: [itemId], token: false });
    },
    buyItem(itemId, price) {
      console.log(`Buying item with ID ${itemId} for ${price}`);
      this.emitter.emit("modal-buy-open", { listings: [itemId]});
    },
    editListing(itemId) {
      console.log(`Editing item with ID ${itemId}`);
      this.emitter.emit("modal-list-open", { inscriptions: [itemId], edit: true });
    },
    sendItem(itemId, opt = {}) {
      console.log(`Sending item with ID ${itemId}`);
      this.emitter.emit("modal-send-open", { inscription_id: itemId, token: opt?.token });
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    isIframe(contentType) {
      if (contentType) {
        return contentType.startsWith("text/html");
      }
      return false;
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("select-bar-closed", () => {
      this.selected_inscriptions = [];
      this.selection_mode = 0;
      this.selection_token = null;
    });
    this.emitter.on("listing-added", (parameters) => {
      this.inscriptions.forEach(item => {
        if (item.inscription_id === parameters.inscription_id) {
          item.listed = {
            value: parameters.value,
          };
        }
      });
    });
    this.emitter.on("listing-cancelled", (parameters) => {
      console.log('cancelled', parameters)
      this.inscriptions.forEach(item => {
        if (item.inscription_id === parameters.inscription_id) {
          item.listed = undefined;
          console.log(item)
        }
      });
    });
    this.loadRunes();
  },
};
</script>

<style scoped>
@media (max-width: 576px) {
  .address h1 {
    font-size: 1.5rem;
  }
}

.inscription-iframe {
  height: 8rem;
  overflow: hidden;
}

.inscription-text {
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
}

.inscription-text>div {
  width: 100%;
  text-align: center;
}

.inscription-token {
  width: 8rem;
  height: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.inscription-token>div {
  font-size: 1.25rem;
}

.inscription-token>div {
  width: 100%;
  text-align: center;
}

.listed-price {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0000004d;
  color: #fff;
  border-radius: 8px;
  padding: 0.2rem 0.4rem;
}

.inscription-container.selected {
  transform: scale(.9);
  border: 2px solid #007aff;
}

.address-history {
  overflow-x: auto;
  padding: 1rem 0 0.5rem 0;
}

.address-inscriptions {
  display: flex;
  grid-gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.default-button {
  width: 100%;
  background-color: #D4D6E1;
  color: #345D9D;
  font-weight: 700;
  transition: all .25s ease-in-out;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.default-button:enabled:hover {
  background-color: #345d9d;
  color: #fff;
}

.list-button {
    background-color: #8fc5ff;
    color: #007aff;
}

.list-button:enabled:hover {
    background-color: #007aff;
    color: #fff;
}

.edit-button {
  background-color: #5f530e;
  color: gold;
}

.edit-button:enabled:hover {
  background-color: #b09600;
  color: #fff;
}

.transfer-button {
  background-color: #3c1295;
  color: #d94fff;
  padding-left: 0.8em;
  padding-right: 0.8em;
  transition: all .25s ease-in-out;
}

.transfer-button:enabled:hover {
  background-color: #9d12c8;
  color: #fff;
}
</style>