<template>
  <div class="listings-header">
    <h1>LTC-20</h1>
  </div>
  <div class="table-container">
    <Ltc20TableComponent :items="tokens"/>
    <InfiniteScrollComponent v-if="allowLoadTokens" @infinite="loadTokens" />
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js'
import utils from '@/utils';

import InfiniteScrollComponent from '@/components/InfiniteScrollComponent.vue';
import Ltc20TableComponent from '@/components/Ltc20TableComponent.vue';

export default {
  data() {
    return {
      page_number: 1,
      allowLoadTokens: true,
      usd_rate: inject('usd_rate', 0),
      tokens: [],
    };
  },
  async beforeRouteEnter(to, from, next) {
    let tokens_resp = await fetch(`${config.apiUrl}/ltc20?sort=transactions`)
      .then(d => d.json());

    var sorted = tokens_resp['result'].sort((a, b) => {
      return b.total_volume - a.total_volume;
    });

    console.log("sort")

    next(vm => {
      vm.tokens = sorted;
    });
  },
  components: {
    InfiniteScrollComponent,
    Ltc20TableComponent
  },
  methods: {
    async loadTokens() {
      if (!this.allowLoadTokens || this.tokens.length < 24) {
        return;
      }

      var tokens_paged = await fetch(`${config.apiUrl}/ltc20?page=${++this.page_number}&sort=transactions`)
        .then(d => d.json());
      
      if (tokens_paged.status == 1) {
        if (tokens_paged.result.length < 24) {
          this.allowLoadTokens = false;
        }

        var sorted = tokens_paged.result.sort((a, b) => {
          return b.total_volume - a.total_volume;
        });

        this.tokens.push(...sorted);
      }
    },
    ...utils,
  },
};
</script>

<style scoped>
img.token-img {
  object-fit: cover;
}

.token-img {
  border-radius: 50%;
}

td.name {
  font-size: 1.1rem;
  font-weight: 600;
}

.volume, .price, .market-cap, .holders {
  white-space: nowrap;
}

.table-row .no-data {
  color: #8a939b;
  font-weight: 400;
}
</style>