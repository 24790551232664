<template>
  <div style="display: flex; gap: 1rem">
    <h1>Inscribe</h1>
    <h1>|</h1>
    <h1 class="etch-link">
      <router-link :to="{ name: 'etch' }">
        Etch
      </router-link>
    </h1>
  </div>
  <form class="inscribe" @submit.prevent="submit">
    <div class="inscribe-tabs">
      <button type="button" :class="{'white': true, 'active': tab == 0}" @click="selectTab(0)">Files</button>
      <button type="button" :class="{'white': true, 'active': tab == 1}" @click="selectTab(1)">Text</button>
      <button type="button" :class="{'white': true, 'active': tab == 2}" @click="selectTab(2)">Delegate</button>
    </div>
    <template v-if="tab == 0">
      <div class="inscribe-header">
        <div v-if="!files || files.length == 0" style="font-weight: bold;">Upload your files to begin</div>
        <div v-if="files.length > 0" style="font-weight: bold;">{{ files.length }} file{{files.length > 1 ? 's' : ''}} selected</div>
        <span v-if="files.length > 0">
          <button class="button outline" type="button" @click="removeFiles">Remove all</button>
        </span>
      </div>
      <label class="inscribe-selector" role="button" for="fileUpload">
        <div v-if="!files || files.length == 0" style="padding: 2rem 0;">
          <font-awesome-icon :icon="['fas', 'cloud-arrow-up']" style="font-size: 5rem; padding: 1rem; opacity: 0.5;"/>
          <div>Click to upload files or drag and drop...</div>
          <div class="upload-notice" style="margin: 1rem">png, jpg, gif, svg, webp, json, bin, pdf, asc, yaml, flac, mp3, wav, otf, ttf, woff, woff2, avif, gltf, glb, stl, css, js, md, html, txt, py, mp4, webm, etc!</div>
        </div>
        <div v-if="files.length > 0" class="file-container">
          <div v-for="file in files" :key="file.id" class="file-upload">
            <div class="file-name">{{ file.name }}</div>
            <div class="file-size">{{ file_size_str(file.size) }}</div>
          </div>
        </div>
      </label>
      <input type="file" id="fileUpload" name="upload[]" @change="handleFiles" style="display: none;" accept multiple require>
      <div v-if="!files || files.length == 0" class="input-invalid">
        Please select at least 1 file for upload.
      </div>
        </template>
        <template v-if="tab==1">
      <div style="display: flex;">
        <input v-model="text_mode" type="radio" id="textModeSingle" name="textMode" value="single" style="height: 1rem; margin-top: 2px;"/>
        <label for="textModeSingle" style="padding: 0 0.5rem;">Single</label>
        <input v-model="text_mode" type="radio" id="textModeBulk" name="textMode" value="bulk" style="height: 1rem; margin-top: 2px;"/>
        <label for="textModeBulk" style="padding: 0 0.5rem;">Bulk</label>
      </div>
      <div class="input-container" style="width: 100%;">
        <textarea v-if="text_mode=='single'" v-model="text_single" style="min-width: calc(100% - 0.7rem); max-width: calc(100% - 0.7rem); min-height: 10rem;" placeholder="Enter text..." name="text"></textarea>
        <textarea v-if="text_mode=='bulk'" v-model="text_bulk" style="min-width: calc(100% - 0.7rem); max-width: calc(100% - 0.7rem); min-height: 10rem;" 
        placeholder="abc
123
ordinal
bulk
..." name="text"></textarea>

      </div>
    </template>
    <template v-if="tab==2">
      <div>Inscription Id:</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="delegate" type="text" placeholder="Inscription Id" name="delegate" autocomplete="off">
        <div v-if="isValidInscription(delegate)" style="align-content: center; padding: 0 1rem; font-size: 20px;">
          <font-awesome-icon :icon="['fas', 'check']" style="color: #3fae2e"/>
        </div>
      </div>
      <div style="margin-top: 1rem;">Repeat (number of times to inscribe):</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="delegate_repeat" type="number" placeholder="1" min="0" max="1000" step="1" name="delegate_repeat">
      </div>
    </template>
    <div class="checkbox-container" style="margin-top: 1rem; width: calc(100% - 1rem);">
      <input type="checkbox" id="metadata" v-model="show_metadata">
      <label for="metadata">Metadata?</label>
    </div>
    <template v-if="show_metadata && totalInscriptions > 0">
      <template v-for="i in totalInscriptions" :key="i">
        <div v-if="totalInscriptions > 1" style="margin-top: 1rem;">
          #{{ i }}
          <span v-if="tab==0">{{ files[i - 1].name }}</span>
          <span v-if="tab==1">Bulk Line</span>
          <span v-if="tab==2">Delegate</span>
        </div>
        <div class="input-container" style="width: 100%; position: relative">
          <div v-if="metadata[i]" style="position: absolute; align-content: center; right: 0; padding: 0.5rem;">
            <font-awesome-icon v-if="isJsonValid(metadata[i])" :icon="['fas', 'check']" style="color: #3fae2e; font-size: 20px;"/>
            <div v-else class="input-invalid" style="display: flex; align-items: center;">
              <div style="padding-right: 0.5rem;">Invalid JSON</div>
              <font-awesome-icon :icon="['fas', 'xmark']" style="font-size: 20px;"/>
            </div>
          </div>
          <textarea v-model="metadata[i]" style="min-width: calc(100% - 0.7rem); max-width: calc(100% - 0.7rem); min-height: 4rem;" placeholder="Enter Metadata (optional)" name="metadata">
          </textarea>
        </div>
        <div class="input-container" style="width: 100%;">
          <input v-model="metaprotocol[i]" type="text" placeholder="Enter Metaprotocol (optional)" name="metaprotocol">
        </div>
      </template>
    </template>
    <div style="margin-top: 1rem;">Receive Address:</div>
    <div class="input-container" style="width: 100%;">
      <input v-model="receiver_address" type="text" placeholder="Receiving LTC Address" name="address" required>
      <div v-if="wallet_address" style="cursor: pointer; align-content: center; margin: 0.4rem 0.5rem; padding: 0 0.5rem; background-color: #ffffff1f; white-space: nowrap; border-radius: 5px;" @click="setDestinationAddress(wallet_address)">
        Set to wallet address
      </div>
    </div>
    <div v-if="receiver_address && !isValidAddress(receiver_address)" class="input-invalid">
      Please input a valid receiving address.
    </div>
    <div v-if="totalInscriptions > 0" class="fee-summary">
      <div class="modal-row">
        <div>Inscription Fee:</div>
        <div class="modal-row-value">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
           {{ totalInscriptions }} x 0.0001 <span class="usd-value">(${{ (totalInscriptions * 0.0001 * usd_rate).toFixed(2) }})</span>
        </div>
      </div>
      <div class="modal-row">
        <div>Network Fee:</div>
          <div class="modal-row-value">
            <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
            ≈ {{ (totalInscriptions * 0.000005).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 8}) }} <span class="usd-value">(${{(totalInscriptions * 0.000005 * usd_rate).toFixed(2)}})</span>
          </div>
      </div>
      <div class="modal-row">
        <div>Size Fee:</div>
        <div class="modal-row-value">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
           {{ satToBtc(totalBytesRound) }} <span class="usd-value">(${{ (satToBtc(totalBytesRound) * usd_rate).toFixed(2) }})</span>
        </div>
      </div>
      <hr style="width: 100%; opacity: 0.2;">
      <div class="modal-row">
        <div>Service Fee:</div>
        <div class="modal-row-value">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
          {{ totalInscriptions * 0.005 }} <span class="usd-value">(${{ (totalInscriptions * 0.005 * usd_rate).toFixed(2) }})</span>
        </div>
      </div>
      <hr style="width: 100%; opacity: 0.2;">
      <div class="modal-row modal-total">
        <div>Total:</div>
        <div class="modal-row-value">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
            {{ totalFee.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 8}) }} <span class="usd-value">(${{ (totalFee * usd_rate ).toFixed(2)}})</span>
        </div>
      </div>
    </div>
    <div style="display: flex; background-color: rgba(0, 0, 0, 0.2); padding: 1rem; border-radius: 12px; text-align: justify; align-items: center;">
      <font-awesome-icon :icon="['fas', 'circle-info']" style="margin: 0 0.5rem;"/>
      <span style="margin-left: 8px;">Please note the inscribing transaction delivers the inscription to the receiving address directly.</span>
    </div>
    <div style="display: flex; width: -webkit-fill-available; text-align: center; margin-top: 1rem;">
      <button class="button confirm-button" type="submit" style="width: 100%; padding: 0.8rem" :disabled="!receiver_address || !isValidAddress(receiver_address) || totalInscriptions == 0">
        <font-awesome-icon v-if="isSubmitting" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
        {{ isSubmitting ? "Creating Order..." : "Submit &amp; Pay invoice" }}
      </button>
    </div>
    <div v-if="error_message" class="input-invalid" style="width: 100%; text-align: center;">
      {{ error_message }}
    </div>
  </form>
  <div class="previous-orders">
    <router-link :to="{ name: 'orders' }">
      <div>Previous Orders</div>
    </router-link>
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js'
import utils from '@/utils.js'

export default {
  data() {
    return {
      isModalVisible: false,
      isSubmitting: false,
      usd_rate: inject('usd_rate', 0),
      wallet_address: inject('wallet_address', false),
      show_metadata: false,
      metadata: [],
      metaprotocol: [],
      receiver_address: null,
      error_message: null,
      files: [],
      text_mode: 'single',
      text_single: null,
      text_bulk: null,
      delegate: null,
      delegate_repeat: 1,
      tab: 0,
    };
  },
  methods: {
    handleFiles(event) {
      const selectedFiles = event.target.files;
      if (!selectedFiles.length) return;
      this.files = Array.from(selectedFiles);
    },
    removeFiles() {
      this.files = [];
    },
    createFile(file_name, contents) {
      const blob = new Blob([contents], { type: 'text/plain' });

      return new File([blob], file_name, { type: 'text/plain' });
    },
    isJsonValid(str) {
      try {
        JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    },
    generateMetadataFile() {
      let metadata_contents = [];

      try {
        for (let i = 1; i <= this.totalInscriptions; i++) {
          metadata_contents.push({
            metadata: this.metadata[i] ? JSON.parse(this.metadata[i]) : undefined,
            metaprotocol: this.metaprotocol[i],
          })
        }
      } catch (e) {
        throw new Error("Metadata JSON invalid");
      }

      return this.createFile('metadata.json', JSON.stringify(metadata_contents))
    },
    isValidInscription(inscriptionId) {
      const pattern = /^[a-f0-9]{64}i\d+/g
      return inscriptionId && pattern.test(inscriptionId);
    },
    setDestinationAddress(address) {
      this.receiver_address = address;
    },
    async submit() {
      this.error_message = null;
      this.isSubmitting = true;
      if (this.totalInscriptions > 0) {
        const formData = new FormData();

        if (this.tab == 0 && this.files.length > 0) {
          this.files.forEach((file) => {
            formData.append(`upload[]`, file);
          });
        } else if (this.tab == 1) {
          if (this.text_mode == 'single') {
            const file = this.createFile('text_single.txt', this.text_single);
            formData.append('upload[]', file);
          } else if (this.text_mode == 'bulk') {
            var lines = this.text_bulk.split(/\r\n|\r|\n/);
            lines.forEach((line, index) => {
              const file = this.createFile(`text_bulk_${index}.txt`, line);
              formData.append('upload[]', file);
            })
          }
        } else if (this.tab == 2 && this.delegate) {
          formData.append('delegate', this.delegate);
          formData.append('delegate_repeat', this.delegate_repeat);
        }

        if (this.show_metadata) {
          try {
            const metadata_file = this.generateMetadataFile();
            formData.append(`upload[]`, metadata_file);
          } catch (error) {
            console.error('Error:', error);
            this.error_message = error;
            this.isSubmitting = false;
            return;
          }
        }

        formData.append('address', this.receiver_address);

        try {
          const response = await fetch(`${config.apiUrl}/order/create`, {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Response error ' + response.status);
          }

          const post_response = await response.json();
          if (post_response.status == 0) {
            this.error_message = post_response.message;
            this.isSubmitting = false;
            return;
          }

          this.saveOrderToBrowserCache({'order_id': post_response.result.order, 'time_created': Math.round(Date.now()/1000)})

          this.$router.push({
            name: "order",
            params: {
              id: post_response.result.order,
            },
          });
        } catch (error) {
          console.error('Error:', error);
          this.error_message = error;
        }
      } else {
        console.log('No files to upload.');
        this.error_message = "No files to upload.";
      }

      this.isSubmitting = false;
    },
    selectTab(tab) {
      this.tab = tab;
    },
    ...utils
  },
  computed: {
    totalInscriptions() {
      if (this.tab == 0) {
        return this.files.length;
      } else if (this.tab == 1) {
        if (this.text_mode == 'single' && this.text_single && this.text_single.length > 0) {
          return 1;
        }
        if (this.text_mode == 'bulk' && this.text_bulk) {
          var lines = this.text_bulk.split(/\r\n|\r|\n/);
          return lines.length;
        }
      } else if (this.tab == 2 && this.delegate_repeat) {
        return Number(this.delegate_repeat);
      }
      return 0;
    },
    totalBytes() {
      var bytes = 0;
      if (this.tab == 0) {
        for (const file of this.files) {
          bytes += file.size;
        }
      } else if (this.tab == 1) {
        if (this.text_mode == 'single' && this.text_single) {
          bytes += this.text_single.length;
        } else if (this.text_mode == 'bulk' && this.text_bulk) {
          bytes += this.text_bulk.length;
        }
      } 

      return bytes;
    },
    totalBytesRound() {
      return Math.floor(this.totalBytes / 1000) * 1000;
    },
    totalFee() {
      return (this.totalInscriptions * (0.0001 + 0.005)) + this.satToBtc(this.totalBytesRound);
    }
  }
};
</script>

<style scoped>
.inscribe {
  display: flex;
  flex-direction: column;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  max-width: 920px;
  margin: auto;
  padding: 2rem;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.inscribe-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.etch-link {
  opacity: 0.4;
}

.etch-link:hover {
  opacity: 1;
}

.etch-link a {
  color: inherit;
}

.inscribe-tabs {
  display: flex;
  gap: 1rem;
}

.active {
  color: #FFF;
  background-color: #345D9D;
}

button.white:hover.active {
  color: #FFF;
  background-color: #345D9D;
}

.inscribe-selector {
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: #ffffff1f;
  border-radius: 5px;
}

.inscribe-container {
  width: 100%;
  overflow: auto;
  text-align: center;
  border-radius: 5px;
  padding: 1rem;
  display: none;
}

.input-invalid {
  color: #ff4500;
}

.input-container {
  display: flex;
  border: 1px solid white;
  border-radius: 8px;
}

.input-container:focus-within {
  outline: white solid 2px;
}

.input-container>input, .input-container>textarea {
  width: 100%;
  padding: 0.6em 0 0.6em 0.6em;
  border: none;
  outline: none;
}

input, textarea {
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  border: 1px solid gray;
  padding: 0.4em;
}

.checkbox-container {
  display: flex;
  column-gap: 1rem;
  background-color: #ffffff1f;
  border-radius: 8px;
  padding: 0.5rem;
}

.file-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-height: 300px;
  overflow: auto;
}

.file-upload {
  margin: 0.5rem;
  padding: 0.5rem;
  background: #0004;
  border-radius: 5px;
}

.file-name {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.fee-summary {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 1rem;
  background-color: #ffffff0f;
  border-radius: 5px;
}

.modal-total {
  margin-top: 0.5em;
  font-size: larger;
}

.previous-orders {
  display: flex;
  margin-top: 2rem;
  width: -webkit-fill-available;
  justify-content: center;
}

.previous-orders a {
  padding: 1rem;
  width: 50%;
  text-align: center;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.previous-orders a:hover {
  color: inherit;
  background-color: #222;
}
</style>