import { script } from 'bitcoinjs-lib';

const OPCODES = {
  OP_RETURN: 0x6a,
  OP_PUSHNUM_13: 0x5d,
};

const Tag = {
  Body: 0,
  Mint: 20,
};

function encode(tag, values, payload) {
  for (const value of values) {
    encodeToVec(tag, payload);
    encodeToVec(value, payload);
  }
}

function encodeToVec(n, v) {
  // eslint-disable-next-line 
  let bigN = BigInt(n);
  while (bigN >= 128) {
      v.push(Number((bigN & 0x7Fn) | 0x80n));
      bigN >>= 7n;
  }
  v.push(Number(bigN & 0x7Fn));
}

export function encipherMint(block, tx) {
  let payload = [];

  encode(Tag.Mint, [block, tx], payload);

  const data = Buffer.from(payload);
  const script_buf = script.compile([
    OPCODES.OP_RETURN,
    OPCODES.OP_PUSHNUM_13,
    data
  ]);

  return script_buf;
}

export function encipherSend(block, tx, amount, output) {
  let payload = [];

  encodeToVec(Tag.Body, payload);
  encodeToVec(block, payload);
  encodeToVec(tx, payload);
  encodeToVec(amount, payload);
  encodeToVec(output, payload);

  const data = Buffer.from(payload);
  const script_buf = script.compile([
    OPCODES.OP_RETURN,
    OPCODES.OP_PUSHNUM_13,
    data
  ]);

  return script_buf;
}

// Example usage:
// let block = 1;
// let tx = 0;
// let payload = [];

// JavaScript does not have the same type coercion as Rust, so we explicitly handle types.
// In Rust, `block.into()` and `tx.into()` are used to convert u64 and u32 into a larger common type if needed.
// encode(Tag.Mint, [block, tx], payload);

export function rune(name) {
  // eslint-disable-next-line
  let x = BigInt(0);

  for (let i = 0; i < name.length; i++) {
    const c = name.charCodeAt(i);
    
    if (i > 0) {
      // eslint-disable-next-line
      x = x + BigInt(1);
    }

    // eslint-disable-next-line
    x = x * BigInt(26);

    if (c >= 65 && c <= 90) {
      // eslint-disable-next-line
      x = x + BigInt(c - 65);
    } else {
      throw new Error(`Invalid character: ${String.fromCharCode(c)}`);
    }
  }

  return x;
}
