<template>
  <div class="order">
    <div class="order-header">
      <div>
        <div style="font-size: larger;">
          <span class="copy" @click="copyContent(order_id)">
            <b>Order {{ order_id }}</b>
            <font-awesome-icon :icon="['fas', 'copy']" style="margin-left: 1rem;"/>
          </span>
        </div>
        <span style="font-size: smaller;" :title="new Date(order.time_created * 1000)">{{ time_ago(order.time_created * 1000) }}</span>
      </div>
      <div>
        <div v-if="order.status == 0" class="order-time-remaining" title="Time remaining">
          <div style="text-align: center;">
          {{ Math.floor(time_remaining / 60).toString().padStart(2, '0') }}:{{ (time_remaining % 60).toString().padStart(2, '0') }}
          </div>
          <small>Time Remaining</small>
        </div>
        <div v-if="order.status == 4">
          <font-awesome-icon :icon="['fas', 'circle-check']" style="font-size: 1.5rem; color: #3fae2e;" />
        </div>
        <div v-if="order.status == 5" class="order-status-icon">
          Order Expired
          <font-awesome-icon :icon="['fas', 'circle-xmark']" style="font-size: 1.5rem; color: grey;" />
        </div>
        <div v-if="order.status == 7" class="order-status-icon">
          User Decision Required
          <font-awesome-icon :icon="['fas', 'circle-xmark']" style="font-size: 1.5rem; color: orange;" />
        </div>
        <div v-if="order.status == 8" class="order-status-icon">
          Error Processing Order
          <font-awesome-icon :icon="['fas', 'circle-xmark']" style="font-size: 1.5rem; color: tomato;" />
        </div>
      </div>
    </div>
    <div class="order-progress" :class="{'status-inactive': order.status == 5 }">
      <div class="status-item" :class="{ 'status-active': order.status == 0, 'status-completed': order.status > 0 && order.status != 5 }">
        <div class="status-shapes">
          <div class="status-circle">
            <font-awesome-icon v-if="order.status > 0 && order.status != 5" :icon="['fas', 'check']" />
          </div>
          <div class="status-line"></div>
        </div>
        <div class="status-label">Awaiting Payment</div>
      </div>
      <div class="status-item" :class="{ 'status-active': order.status == 1 || order.status == 7, 'status-error': order.status == 7, 'status-completed': order.status > 1 && order.status != 5 }">
        <div class="status-shapes">
          <div class="status-circle">
            <font-awesome-icon  v-if="order.status > 1 && order.status != 5" :icon="['fas', 'check']" />
          </div>
          <div class="status-line"></div>
        </div>
        <div v-if="order.status != 7" class="status-label">Payment Confirmation</div>
        <div v-if="order.status == 7" class="status-label">Awaiting User Input</div>
      </div>
      <div class="status-item" :class="{ 'status-active': order.status == 2 || order.status == 3 || order.status == 8, 'status-error': order.status == 8, 'status-completed': order.status > 3 && order.status < 5 }">
        <div class="status-shapes">
          <div class="status-circle">
            <font-awesome-icon v-if="order.status > 3 && order.status < 5" :icon="['fas', 'check']" />
            <font-awesome-icon v-if="order.status == 8" :icon="['fas', 'xmark']" />
          </div>
          <div class="status-line"></div>
        </div>
        <div class="status-label">Processing Order</div>
      </div>
      <div class="status-item" :class="{ 'status-completed': order.status == 4 }">
        <div class="status-shapes">
          <div class="status-circle">
            <font-awesome-icon v-if="order.status == 4" :icon="['fas', 'check']" />
          </div>
        </div>
        <div class="status-label">Done</div>
      </div>
    </div>
    <div class="order-details">
      <div>
          Send exactly <b class="copy" style="font-size: larger;" @click="copyContent(satToBtc(order.amount_to_pay))">
            {{ satToBtc(order.amount_to_pay) }} LTC (${{ (satToBtc(order.amount_to_pay) * usd_rate).toFixed(2) }})
          </b> to the address:
      </div>
      <div>
        <p class="copy" @click="copyContent(order.payment_address)">
          <b style="word-break: break-all;">{{ order.payment_address }}</b>
          <font-awesome-icon :icon="['fas', 'copy']" style="margin-left: 1rem;"/>
        </p>
      </div>
      <div class="qr-code">
        <QrCodeVue
          :value="`litecoin:${order.payment_address}?amount=${satToBtc(order.amount_to_pay)}`"
          :size=200
          :render-as="'svg'"
          :margin=1
          />
          <div style="color: black;"><small><b>Payment Address</b></small></div>
          <button v-if="order.status < 5" :class="{'pay-button': true, 'confirm-button': true, 'paid-button': this.paid }" :disabled="!this.wallet_connected || this.paid || this.payment_processing" @click="payInvoice">
            <span v-if="!this.paid && this.wallet_connected" class="pay-icon">
              <font-awesome-icon v-if="!this.payment_processing" :icon="['fas', 'dollar-sign']" /> 
              <font-awesome-icon v-if="this.payment_processing" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
            </span>
            <span class="pay-text">{{ !this.paid ? (!this.wallet_connected ? "Connect Wallet" : "Pay") : "Paid" }}</span>
          </button>
      </div>
    </div>
    <div v-if="order.status == 4 && order.files.length == 0" style="display: flex; background-color: rgba(0, 0, 0, 0.2); padding: 1rem; border-radius: 12px; text-align: justify; align-items: center; width: -webkit-fill-available;">
      <font-awesome-icon :icon="['fas', 'circle-info']" style="margin: 0 0.5rem;"/>
      <span style="margin-left: 8px;">Rune etching require a minimum of 6 block confirmations before revealing at the destination address.</span>
    </div>
    <div v-if="order.files && order.files.length > 0" style="width: 100%">
      <div class="copy files-header" @click="copyFiles()">
        <h3 style="width: auto;">
          Files ({{ order.files.length }})
          <span>
            <font-awesome-icon :icon="['fas', 'copy']" style="margin-left: 1rem;"/>
            <tippy to="parent">Export File Details</tippy>
          </span>
        </h3>
      </div>
      <div class="files-container">
        <div v-for="(file, index) in order.files" :key="index" class="order-file">
          <div style="display: flex; gap: 1rem;">
            <div class="file-icon">
              <font-awesome-icon :icon="['fas', getFileIcon(file.file_name)]" />
            </div>
            <div>
              <router-link v-if="file.inscription_id && file.inscription_id.length != 0" :to="{ name: 'inscription', params: { id: file.inscription_id }}">
                <div>{{ file.file_name }}</div>
              </router-link>
              <div v-if="!file.inscription_id || file.inscription_id.length == 0">
                <div>{{ file.file_name }}</div>
              </div>
              <div class="file-size">{{ file_size_str(file.file_size) }}</div>
            </div>
          </div>
          <div v-if="file.inscription_id && file.inscription_id.length != 0" class="file-success">
            <font-awesome-icon :icon="['fas', 'check']" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="previous-orders">
    <router-link :to="{ name: 'orders' }">
      <div>Previous Orders</div>
    </router-link>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useTippy } from 'vue-tippy';
import { useToast } from "vue-toastification";
import config from '@/config.js'
import utils from '@/utils.js'
import QrCodeVue from 'qrcode.vue';

export default {
  setup() {
    const toast = useToast();
    const tippyCopied = ref(null);
    useTippy(tippyCopied);

    return { toast, tippyCopied };
  },
  data() {
    return {
      usd_rate: inject('usd_rate', 0),
      wallet_address: inject('wallet_address', false),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_provider: this.$walletProvider,
      order_id: null,
      order: {},
      paid: false,
      payment_processing: false,
      time_remaining: 0,
      order_expiry_time: null,
      current_time: Date.now() / 1000,
      files: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    fetch(`${config.apiUrl}/order/status?order=${to.params.id}`)
      .then(d => d.json())
      .then(data => {
        // Pass the fetched data to the next callback
        if (data.status == 1) {
          next(vm => {
            vm.order = data.result;
            vm.order_id = to.params.id;
            vm.time_remaining = data.result.time_remaining;
            vm.order_expiry_time = Date.now() / 1000 + data.result.time_remaining;
            vm.paid = data.result.status >= 2 && data.result.status != 5;
          });
        } else {
          next(from.path);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        next(from.path);
      })
  },
  mounted() {
    this.startTimer();
    this.startFetchInterval(this.order_id);
  },
  beforeUnmount() {
    this.stopTimer();
  },
  components: {
    QrCodeVue
  },
  methods: {
    startFetchInterval(orderId) {
      this.timer_interval = setInterval(() => {
        fetch(`${config.apiUrl}/order/status?order=${orderId}`)
          .then(d => d.json())
          .then(data => {
            if (data.status == 1) {
              this.order = data.result;
              if (this.order.status >= 2 && this.order.status != 5) {
                this.paid = true;
              }
            }
            if (data.status == 0 || (this.order && (this.order.status == 4 || this.order.status == 5))) {
              clearInterval(this.timer_interval);
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            clearInterval(this.timer_interval);
          });
      }, 10000);
    },
    copyContent(content) {
      const input = document.createElement('textarea');
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);

      // this.tippyCopied.show();
      // setTimeout(() => {
      //     this.tippyCopied.hide();
      // }, 2000);
    },
    copyFiles() {
      let fileCopyCsv = "file_name,inscription_id\n";

      this.order.files.forEach(file => {
        fileCopyCsv += `${file.file_name},${file.inscription_id}\n`
      });

      this.copyContent(fileCopyCsv);

      this.toast.success("Copied file details to clipboard", {
        position: "bottom-right"});
    },
    async payInvoice() {
      this.payment_processing = true;
      try {
        await this.wallet_provider.sendBitcoin(this.order.payment_address, Number(this.order.amount_to_pay));
      } catch (e) {
        console.warn(e);
        this.toast.error(e.message, {
          position: "bottom-right"});
        this.payment_processing = false;
        return;
      }
      this.toast.success("Order paid successfully", {
        position: "bottom-right"});
      this.payment_processing = false;
      this.paid = true;
    },
    orderStatus(status) {
      switch(status) {
        case 0:
          return "Awaiting Payment";
        case 1:
          return "Awaiting Transaction Confirmations";
        case 2:
          return "Payment Confirmed";
        case 3:
          return "Order Processing";
        case 4:
          return "Order Completed";
        case 5:
          return "Order Expired";
        case 7:
          return "Awaiting User Decision";
        case 8:
          return "Failed Processing";
      }
      return "Unknown Order Status";
    },
    getFileIcon(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
        case 'txt':
        case 'md':
        case 'log':
          return 'file-lines';
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
        case 'svg':
        case 'webp':
          return 'file-image';
        case 'js':
        case 'java':
        case 'cpp':
        case 'py':
        case 'html':
        case 'css':
        case 'php':
        case 'json':
        case 'csv':
          return 'file-code';
        case 'pdf':
          return 'file-pdf';
        case 'mp3':
        case 'wav':
        case 'ogg':
          return 'file-audio';
        case 'mp4':
        case 'mkv':
        case 'avi':
        case 'mov':
          return 'file-video';
        case 'zip':
        case 'rar':
        case 'tar':
        case 'gz':
          return 'file-archive';
        case 'doc':
        case 'docx':
          return 'file-word';
        case 'xls':
        case 'xlsx':
          return 'file-excel';
        case 'ppt':
        case 'pptx':
          return 'file-powerpoint';
        default:
          return 'file';
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.updateTimeRemaining();
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    updateTimeRemaining() {
      this.time_remaining = Math.floor(this.order_expiry_time - Date.now() / 1000);
      if (this.time_remaining < 0) {
        this.time_remaining = 0;
      }
    },
    ...utils
  },
};
</script>

<style scoped>
.order {
  display: flex;
  flex-direction: column;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  max-width: 920px;
  margin: auto;
  padding: 2rem;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-time-remaining {
  padding: 0.8rem;
  background-color: #333;
  border-radius: 5px;
}

.order-status-icon {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.order-progress {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.status-inactive {
  opacity: 0.3;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 25%;
  opacity: 0.3;
}

.status-item.status-active,
.status-item.status-completed,
.status-item.status-error {
  opacity: 1;
}

.status-active .status-line {
  opacity: 0.3;
}

.status-completed .status-circle,
.status-completed .status-line {
  background-color: #345D9D;
}

.status-completed .status-circle {
  background-color: #345D9D;
}

.order-progress .status-item:last-child {
  width: auto;
}

.status-shapes {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.status-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ccc;
}

.status-item:not(.status-completed):not(.status-error) .status-circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

.status-item.status-active:not(.status-error) .status-circle {
  animation: pulsate 2s infinite;
}

@keyframes pulsate {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #345D9D;
  }
  100% {
    background-color: #ccc;
  }
}

.status-error .status-circle {
  background-color: tomato;
}

.status-line {
  flex-grow: 1;
  height: 2px;
  background-color: #ccc;
}

.status-label {
  font-size: 0.8rem;
  padding-right: 1rem;
}

.order-details {
  width: -webkit-fill-available;
  text-align: center;
}

.qr-code {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  background: #FFF;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 5px;
}

.pay-button {
  margin-top: 0.5rem;
  border-radius: 5px;
}

.pay-icon {
  margin-right: 0.5rem;
}

.paid-button {
  background-color: #3fae2e;
}

.copy {
  cursor: pointer;
}

.input-invalid {
  color: #ff4500;
}

.input-container {
  display: flex;
  border: 1px solid white;
  border-radius: 8px;
}

.input-container:focus-within {
  outline: white solid 2px;
}

.input-container>input {
  width: 100%;
  padding: 0.6em 0 0.6em 0.6em;
  border: none;
  outline: none;
}

input {
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  border: 1px solid gray;
  padding: 0.4em;
}

.file-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-height: 300px;
  overflow: auto;
}

.file-upload {
  margin: 0.5rem;
  padding: 0.5rem;
  background: #0004;
  border-radius: 5px;
}

.file-name {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.fee-summary {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 1rem;
  background-color: #ffffff0f;
  border-radius: 5px;
}

.files-header {
  display: flex;
  align-content: center;
}

.files-container {
  max-height: 500px;
  padding: 0 0.5rem 0 0;
  overflow-y: auto;
  scroll-padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.order-file {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: -webkit-fill-available;
  padding: 0.5rem 1rem;
  background-color: #ffffff0f;
  border-radius: 5px;
}

.file-icon {
  align-content: center;
  font-size: 1.5rem;
}

.file-success {
  align-content: center;
  color: #3fae2e;
  font-size: 1.5rem;
}

.file-size {
  opacity: 0.6;
}

.previous-orders {
  display: flex;
  margin-top: 2rem;
  width: -webkit-fill-available;
  justify-content: center;
}

.previous-orders a {
  padding: 1rem;
  width: 50%;
  text-align: center;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.previous-orders a:hover {
  color: inherit;
  background-color: #222;
}
</style>