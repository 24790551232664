import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faGlobe, faDollarSign, faStar, faFile, faFileLines, faFileAudio, faFileCode, faFileImage, faFilePdf, faFileVideo, faFileArchive, faFileWord, faFileExcel, faFilePowerpoint, faPencil, faQrcode, faScroll, faPaperPlane, faCloudArrowUp, faArrowUpFromBracket, faCircleInfo, faCircleXmark, faCircleCheck, faWallet, faEye, faEyeSlash, faArrowUpRightFromSquare, faKey, faLock, faTrashCan, faCopy, faRotate, faSpinner, faEllipsisVertical, faArrowLeft, faTag, faXmark, faBan, faPlus, faBroom, faShoppingCart, faArrowUpWideShort, faFilter, faCheck, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toast from 'vue-toastification'
import mitt from 'mitt';
import VueTippy from 'vue-tippy'
import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import router from './router'
import "vue-toastification/dist/index.css"
import "@vueform/toggle/themes/default.css"
import { WalletProvider } from './utils/web3';

const emitter = mitt();

library.add(faSearch, faGlobe, faDollarSign, faStar, faFile, faFileLines, faFileAudio, faFileCode, faFileImage, faFilePdf, faFileVideo, faFileArchive, faFileWord, faFileExcel, faFilePowerpoint, faPencil, faQrcode, faScroll, faPaperPlane, faCloudArrowUp, faArrowUpFromBracket, faCircleInfo, faCircleXmark, faCircleCheck, faWallet, faEye, faEyeSlash, faArrowUpRightFromSquare, faKey, faLock, faTrashCan, faCopy, faRotate, faSpinner, faEllipsisVertical, faArrowLeft, faTag, faXmark, faBan, faPlus, faBroom, faShoppingCart, faArrowUpWideShort, faFilter, faCheck, faChevronLeft, faChevronRight, faTwitter, faDiscord, faTelegram)

const app = createApp(App)
const head = createHead();

app.use(router)

app.use(head)

app.use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
})

app.use(Toast);

app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$walletProvider = WalletProvider;

app.mount('#app')
