import config from '@/config';
import utils from '@/utils';

import { LitescribeWallet } from './wallet/litescribe-wallet.js';
import { GateWallet } from './wallet/gate-wallet.js';
import { ChikunWallet } from './wallet/chikun-wallet.js';
import { encipherSend, encipherMint } from './runes.js';

import { payments, Psbt, Transaction } from 'bitcoinjs-lib';

const LITECOIN_NETWORKS = {
  LITECOIN_MAINNET: {
    messagePrefix: '\x19Litecoin Signed Message:\n',
    bech32: 'ltc',
    bip32: {
      public: 0x019da462,
      private: 0x019d9cfe
    },
    bip44: 2,
    pubKeyHash: 0x30,
    scriptHash: 0x32, // for segwit (start with M)
    wif: 0xb0,
  },

  LITECOIN_TESTNET: {
    messagePrefix: '\x19Litecoin Signed Message:\n',
    bech32: 'tltc',
    bip32: {
      public: 0x043587cf,
      private: 0x04358394
    },
    bip44: 1,
    pubKeyHash: 0x6f,
    scriptHash: 0xc4, // for segwit (start with M)
    wif: 0xef,
  },
};

export const WalletProvider = {
  ORDINALS_URL: "https://ordinalslite.com",
  MEMPOOL_URL: config.network === "MAINNET" ? "https://litecoinspace.org" : "https://litecoinspace.org/testnet",
  MEMPOOL_API_URL: config.network === "MAINNET" ? "https://litecoinspace.org/api" : "https://litecoinspace.org/testnet/api",
  COIN: "LTC",
  DUMMY_UTXO_VALUE: 3_000,
  NUMBER_DUMMIES_REQUIRED: 2,
  BUYING_PSBT_SELLER_SIGNATURE_INDEX: 2,
  SERVICE_FEE_ADDRESS: config.serviceFeeAddress,
  SERVICE_FEE_RATE: config.serviceFee / 100 + 1,
  MINT_FEE: config.mintFee,

  LITECOIN_NETWORK: config.network === "MAINNET" ? LITECOIN_NETWORKS.LITECOIN_MAINNET : LITECOIN_NETWORKS.LITECOIN_TESTNET,

  network: null,

  currentWallet: null,
  walletName: null,
  walletConnected: false,
  walletAddress: '',

  bearerToken: null,

  recommendedFeeRate: 1,

  txHexByIdCache: [],
  sellerSignedPsbt: null,
  priceCache: 0,
  paymentUtxos: [],
  dummyUtxos: [],
  dummyUtxo: undefined,

  selectWallet(wallet) {
    if (wallet === 'litescribe') {
      this.currentWallet = LitescribeWallet;
    } else if (wallet === 'gate') {
      this.currentWallet = GateWallet;
    } else if (wallet === 'builtin') {
      this.currentWallet = ChikunWallet;
    } else {
      throw new Error('Invalid wallet selection');
    }
    this.setProvider(this);
    this.walletName = wallet;
  },

  getSelectedWallet() {
    return this.currentWallet;
  },

  getSelectedWalletName() {
    return this.walletName;
  },

  setProvider(provider) {
    this._ensureWalletSelected();
    this.currentWallet.setProvider(provider);
  },

  initialize() {
    this._ensureWalletSelected();
    return this.currentWallet.initialize();
  },

  getWalletAddress() {
    this._ensureWalletSelected();
    return this.currentWallet.getWalletAddress();
  },

  getBalance() {
    this._ensureWalletSelected();
    return this.currentWallet.getBalance();
  },

  getInscriptions() {
    this._ensureWalletSelected();
    return this.currentWallet.getInscriptions();
  },

  sendBitcoin(address, amount) {
    this._ensureWalletSelected();
    return this.currentWallet.sendBitcoin(address, amount);
  },

  sendInscription(address, id) {
    this._ensureWalletSelected();
    return this.currentWallet.sendInscription(address, id);
  },

  signPsbt(psbtBase64) {
    this._ensureWalletSelected();
    return this.currentWallet.signPsbt(psbtBase64);
  },

  signPsbts(psbtsBase64) {
    this._ensureWalletSelected();
    return this.currentWallet.signPsbts(psbtsBase64);
  },

  signMessage(message) {
    this._ensureWalletSelected();
    return this.currentWallet.signMessage(message);
  },

  setSeedPhrase(seedphrase) {
    this._ensureWalletSelected();
    return this.currentWallet.setSeedPhrase(seedphrase);
  },

  async fetchChallenge() {
    var challenge_resp = await fetch(`${config.apiUrl}/auth/challenge?address=${await this.getWalletAddress()}`)
        .then(d => d.json());

    return challenge_resp.result.challenge
  },

  async submitChallenge(signature) {
      const response = await fetch(`${config.apiUrl}/auth`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          body: JSON.stringify({
              address: await this.getWalletAddress(),
              signature: signature,
          }),
      }).then(d => d.json());
      if (response.status == 0) {
          throw new Error(response.message);
      }
      return response.result.token;
  },

  getAuth() {
    this._ensureWalletSelected();
    return this.bearerToken;
  },

  setAuth(auth) {
    this._ensureWalletSelected();
    this.bearerToken = auth;
  },

  async authenticateWallet() {
    // Disable authentication for now
    // sign challenge message to authenticate wallet
    // const challenge = await this.fetchChallenge();

    // const signed_challenge = await this.signMessage(challenge);
    
    // const token = await this.submitChallenge(signed_challenge);

    // this.setAuth(token);
  },

  lockWallet() {
    this._ensureWalletSelected();
    return this.currentWallet.lockWallet();
  },

  isWalletInstalled() {
    this._ensureWalletSelected();
    return this.currentWallet.isWalletInstalled();
  },

  // Private helper method to ensure a wallet has been selected
  _ensureWalletSelected() {
    if (!this.currentWallet) {
      throw new Error('No wallet selected. Please select a wallet first.');
    }
  },

  async signPSBTUsingWalletAndBroadcast(psbtBase64) {
    const signedPsbtHex = await this.signPsbt(psbtBase64);
    const txId = await this.broadcastTx(this.psbtBase64ToTransactionHex(utils.hexToBase64(signedPsbtHex)));

    window.open(`${this.MEMPOOL_URL}/tx/${txId}`, "_blank");
    return txId;
  },

  psbtBase64ToTransactionHex(signedPsbtBase64) {
    const signedPsbt = Psbt.fromBase64(signedPsbtBase64, { network: WalletProvider.LITECOIN_NETWORK });
    return signedPsbt.extractTransaction().toHex();
  },

  async broadcastTx(txHex) {
    const res = await fetch(`${this.MEMPOOL_API_URL}/tx`, {
      method: "post",
      body: txHex,
    });
    if (res.status != 200) {
      throw new Error(`Mempool API returned ${res.status} ${res.statusText
        }\n\n${await res.text()}`
      );
    }

    return await res.text();
  },

  async getTxHexById(txId) {
    if (!this.txHexByIdCache[txId] || this.txHexByIdCache[txId].length == 0) {
      this.txHexByIdCache[txId] = await fetch(
        `${this.MEMPOOL_API_URL}/tx/${txId}/hex`
      ).then((response) => response.text());
    }
  
    return this.txHexByIdCache[txId];
  },

  async getAddressUtxos(address) {
    try {
      return await fetch(`${this.MEMPOOL_API_URL}/address/${address}/utxo`).then(
        (response) => response.json()
      );
    } catch (e) {
      console.error(`Too many utxos (>500) in address`);
    }

    var backup = await fetch(`${config.apiUrl}/address/utxos?address=${address}`).then(
      (response) => response.json()
    );
    if (backup.status == 1) {
      return backup.result;
    }
    throw new Error("Error fetching address utxos");
  },

  isSegwitScript(script) {
    return this.isP2WPKH(script) || this.isP2WSH(script);
  },

  isP2WPKH(script) {
    try {
      const p2wpkh = payments.p2wpkh({ output: script, network: WalletProvider.LITECOIN_NETWORK });
      return p2wpkh.address;
    } catch (e) {
      // If decoding fails, it's not a recognized SegWit script
      return false;
    }
  },

  isP2WSH(script) {
    try {
      const p2wsh = payments.p2wsh({ output: script, network: WalletProvider.LITECOIN_NETWORK });
      return p2wsh.address;
    } catch (e) {
      // If decoding fails, it's not a recognized SegWit script
      return false;
    }
  },

  async getInscription(inscriptionId) {
    var output = null;
    const results = await fetch(`${config.apiUrl}/inscription/${inscriptionId}`)
      .then(d => d.json())
    if (results.status == 1) {
      output = results.result;
    }

    return output;
  },

  async getInscriptionOutput(inscriptionId) {
    var output = null;
    const result = await this.getInscription(inscriptionId);
    if (result) {
      var split = result.location.split(":");
      output = split[0] + ":" + split[1];
    }
    return output;
  },

  async getRunicUtxos(utxos, rune = null, amount = null) {
    const outpoints = [];

    for (const utxo of utxos) {
      outpoints.push(`${utxo.txid}:${utxo.vout}`);
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ outpoints })
    };

    const results = await fetch(`${config.apiUrl}/runes/utxos`, requestOptions)
      .then(d => d.json());

    var rune_amount = 0;

    var runic_utxos = [];

    if (results.status == 1) {
      var runic_utxos_all = results.result;
      for (const utxo of runic_utxos_all) {
        const key = Object.keys(utxo)[0];
        
        if (utxo[key]['runes'] && Object.keys(utxo[key]['runes']).length > 0) {
          if (rune != null) {
            var contains_rune = false;
            for (const rune_edict of utxo[key]['runes']) {
              if (rune_edict[0] === rune) {
                rune_amount = rune_amount + rune_edict[1].amount;
                contains_rune = true;
              }
            }
            if (contains_rune) {
              runic_utxos.push(utxo);
            }
            if (amount != null && rune_amount >= amount) {
              break;
            }
          } else {
            runic_utxos.push(utxo);
          }
        }
      }
    }

    return runic_utxos;
  },

  async getInscriptionBalance(address) {
    let ordinalBalance = 0;

    console.log(address);

    return ordinalBalance;
  },

  async getRunicBalances(address) {
    var addressUtxos = await this.getAddressUtxos(address);

    var runicUtxos = await this.getRunicUtxos(addressUtxos);

    console.log(runicUtxos);

    var rune_balances = [];

    for (const utxo of runicUtxos) {
      const key = Object.keys(utxo)[0];

      for (const rune_key of Object.keys(utxo[key]['runes'])) {
        const rune_edict = utxo[key]['runes'][rune_key];
      
        var divisibility = rune_edict['divisibility'];
        var symbol = rune_edict['symbol'];
        var amount = rune_edict['amount'];
      
        if (!rune_balances[rune_key]) {
          rune_balances[rune_key] = {
            divisibility: divisibility,
            symbol: symbol,
            amount: 0,
            utxos: [],
          };
        }
      
        rune_balances[rune_key].amount += amount;
        rune_balances[rune_key].utxos.push({ outpoint: key, amount: amount });
      }
    }

    // Transform map to array
    var rune_balances_array = Object.keys(rune_balances).map(key => ({
        spaced_rune: key,
        divisibility: rune_balances[key].divisibility,
        symbol: rune_balances[key].symbol,
        amount: rune_balances[key].amount,
        utxos: rune_balances[key].utxos,
    }));

    return rune_balances_array;
},

  async doesUtxoContainInscription(utxo) {
    const html = await fetch(
      `${this.ORDINALS_URL}/output/${utxo.txid}:${utxo.vout}`
    ).then((response) => response.text());

    return html.match(/class=thumbnails/) !== null || html.match(/<dt>runes/) !== null;
  },

  async selectUtxos(utxos, amount, vins, vouts, recommendedFeeRate) {
    const selectedUtxos = [];
    let selectedAmount = 0;

    // Sort descending by value, and filter out dummy utxos
    utxos = utxos
      .filter((x) => x.value > this.DUMMY_UTXO_VALUE)
      .sort((a, b) => b.value - a.value);

    for (const utxo of utxos) {
      // Never spend a utxo that contains an inscription for cardinal purposes
      if (await this.doesUtxoContainInscription(utxo)) {
        continue;
      }
      selectedUtxos.push(utxo);
      selectedAmount += utxo.value;

      if (
        selectedAmount >=
        amount +
        this.DUMMY_UTXO_VALUE +
        this.calculateFee(vins + selectedUtxos.length, vouts, recommendedFeeRate)
      ) {
        break;
      }
    }

    if (selectedAmount < amount) {
      throw new Error(`Not enough cardinal spendable funds.
        Address has:  ${utils.satToBtc(selectedAmount)} ${this.COIN}
        Needed:          ${utils.satToBtc(amount)} ${this.COIN}
        
        UTXOs:
        ${utxos.map((x) => `${x.txid}:${x.vout}`).join("\n")}`);
    }

    return selectedUtxos;
  },

  async generatePSBTListingInscriptionForSale(ordinalOutput, price, paymentAddress) {
    let psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
  
    const [ordinalUtxoTxId, ordinalUtxoVout] = ordinalOutput.split(":");
    const tx = Transaction.fromHex(await this.getTxHexById(ordinalUtxoTxId));
  
    const isSegwit = this.isSegwitScript(tx.outs[ordinalUtxoVout].script);

    const input = {
      hash: ordinalUtxoTxId,
      index: parseInt(ordinalUtxoVout),
      sighashType:
        Transaction.SIGHASH_SINGLE |
        Transaction.SIGHASH_ANYONECANPAY,
    };

    if (isSegwit) {
      try {
        tx.setWitness(parseInt(ordinalUtxoVout), []);
      } catch (e) {
        console.warn(e)
      }
      input.witnessUtxo = tx.outs[ordinalUtxoVout];
    } else {
      input.nonWitnessUtxo = tx.toBuffer();
    }
  
    psbt.addInput(input);
  
    psbt.addOutput({
      address: paymentAddress,
      value: price,
    });
  
    console.log(psbt);

    return psbt.toBase64();
  },

  async generatePSBTInscriptionSend(senderAddress, receiverAddress, inscription, excludeFee = false) {
    let psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
  
    const output = await this.getInscriptionOutput(inscription);
    const [ordinalUtxoTxId, ordinalUtxoVout] = output.split(":");
    const tx = Transaction.fromHex(await this.getTxHexById(ordinalUtxoTxId));
    const networkfee = excludeFee ? 0 : 500;
  
    const input = {
      hash: ordinalUtxoTxId,
      index: parseInt(ordinalUtxoVout),
      nonWitnessUtxo: tx.toBuffer(),
      witnessUtxo: tx.outs[ordinalUtxoVout]
    };
  
    psbt.addInput(input);
  
    // Add address minus a network fee
    psbt.addOutput({
      address: receiverAddress,
      value: tx.outs[ordinalUtxoVout].value - networkfee
    });

    if (excludeFee) {
      let totalValue = 0;

      for (const utxo of this.paymentUtxos) {
        const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));

        psbt.addInput({
          hash: utxo.txid,
          index: utxo.vout,
          nonWitnessUtxo: tx.toBuffer(),
          witnessUtxo: tx.outs[utxo.vout],
        });
  
        totalValue += utxo.value;
      }

      const fee = this.calculateFee(
        psbt.txInputs.length,
        psbt.txOutputs.length,
        await this.recommendedFeeRate
      );

      const changeValue = totalValue - fee;

      // Change utxo
      psbt.addOutput({
        address: senderAddress,
        value: changeValue,
      });
    }
  
    return psbt.toBase64();
  },

  async generatePSBTBuyingInscription(payerAddress, receiverAddress, price, paymentUtxos, dummyUtxos, sellerSignedPsbts = null, royalties = null) {
    const psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
    let totalValue = 0;
    let totalPaymentValue = 0;
    let input = 0;

    console.log(price)

    let dummiesRequired = 2;

    if (sellerSignedPsbts != null && sellerSignedPsbts.length > 1) {
        dummiesRequired = sellerSignedPsbts.length + 1;
    }

    let dummyOutputValue = 0;

    // Add two or more dummy utxos inputs
    for (var i = 0; i < dummiesRequired; i++) {
      const tx = Transaction.fromHex(await this.getTxHexById(dummyUtxos[i].txid));
      try {
        tx.setWitness(parseInt(tx.outs[dummyUtxos[i].vout]), []);
      } catch (e) {
        console.warn(e)
      }

      psbt.addInput({
        hash: dummyUtxos[i].txid,
        index: dummyUtxos[i].vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[dummyUtxo.vout],
      });

      input++;

      dummyOutputValue += dummyUtxos[i].value;
    }

    // Add dummy output
    psbt.addOutput({
      address: receiverAddress,
      value: dummyOutputValue,
    });

    if (sellerSignedPsbts != undefined) {
      // Using sellerSignedPsbts for bulk buying

      // Add inscriptions inputs and outputs
      for (const signedPsbt of sellerSignedPsbts) {
        // Add inscription output
        psbt.addOutput({
          address: receiverAddress,
          value: signedPsbt.data.inputs[0].witnessUtxo.value,
        });

        // Add payer signed input
        psbt.addInput({
          ...signedPsbt.data.globalMap.unsignedTx.tx.ins[0],
          // ...signedPsbt.data.inputs[0],
          witnessUtxo: signedPsbt.data.inputs[0].witnessUtxo,
          // nonWitnessUtxo: signedPsbt.data.inputs[0].nonWitnessUtxo,
          // finalScriptWitness: signedPsbt.data.inputs[0].finalScriptWitness,
        });

        input++;
      }

      // Add seller payment outputs for payee
      for (const signedPsbt of sellerSignedPsbts) {
        // Add payer output
        psbt.addOutput({
          ...signedPsbt.data.globalMap.unsignedTx.tx.outs[0],
        });
      }
    } else {
      // Default method if sellerSignedPsbts array isn't set

      // Add inscription output
      psbt.addOutput({
        address: receiverAddress,
        value: this.sellerSignedPsbt.data.inputs[0].witnessUtxo.value,
      });

      // Add payer signed input
      psbt.addInput({
        ...this.sellerSignedPsbt.data.globalMap.unsignedTx.tx.ins[0],
        ...this.sellerSignedPsbt.data.inputs[0],
      });

      input++;

      // Add payer output
      psbt.addOutput({
        ...this.sellerSignedPsbt.data.globalMap.unsignedTx.tx.outs[0],
      });
    }

    // Add payment utxo inputs
    for (const utxo of paymentUtxos) {
      const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));
      try {
        tx.setWitness(parseInt(tx.outs[utxo.vout]), []);
      } catch (e) {
        console.log(e)
      }
      
      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[utxo.vout],
      });

      input++;
      totalValue += utxo.value;
      totalPaymentValue += utxo.value;
    }

    // Service fee
    var market_fee = 0;
    if (this.SERVICE_FEE_ADDRESS != undefined && this.SERVICE_FEE_RATE != undefined) {
      market_fee = Math.round(price * this.SERVICE_FEE_RATE - price);
      console.log("FeeRate: %s, Service_fee: %s", this.SERVICE_FEE_RATE, market_fee);
      if (market_fee > 0) {
        psbt.addOutput({
          address: this.SERVICE_FEE_ADDRESS,
          value: market_fee,
        });
      }
    }

    // Royalties
    var royalties_total = 0;
    
    for (const address in royalties) {
      if (Object.hasOwn(royalties, address)) {
        psbt.addOutput({
            address: address,
            value: royalties[address],
        });
        royalties_total += royalties[address];
      }
    }

    // Inputs
    console.log(`Inputs: ${input}`)

    // Create new dummy utxos outputs for the next purchase
    for (let i = 0; i < dummiesRequired; i++) {
      psbt.addOutput({
        address: payerAddress,
        value: this.DUMMY_UTXO_VALUE,
      });
    }

    const fee = this.calculateFee(
      psbt.txInputs.length,
      psbt.txOutputs.length,
      await this.recommendedFeeRate
    );

    const changeValue = totalValue - (this.dummyUtxo.value * dummiesRequired) - price - market_fee - royalties_total - fee;

    if (changeValue < 0) {
      throw `Your wallet address doesn't have enough funds to buy this inscription.
        Price:      ${utils.satToBtc(price)} ${this.COIN}
        Fees:       ${utils.satToBtc(fee + market_fee + royalties_total + this.DUMMY_UTXO_VALUE * dummiesRequired)} ${this.COIN}
        You have:   ${utils.satToBtc(totalPaymentValue)} ${this.COIN}
        Required:   ${utils.satToBtc(totalValue - changeValue)} ${this.COIN}
        Missing:    ${utils.satToBtc(-changeValue)} ${this.COIN}`;
    }

    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: changeValue,
    });

    return psbt.toBase64();
  },

  async generatePSBTBuyingRune(payerAddress, receiverAddress, price, rune, paymentUtxos, sellerSignedPsbts) {
    const psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
    let totalValue = 0;
    let totalPaymentValue = 0;
    let input = 0;
    let output = 0;

    console.log(price)

    // Using sellerSignedPsbts for bulk buying
    // Add inscriptions inputs and outputs
    for (const signedPsbt of sellerSignedPsbts) {
      // Add payer signed input
      psbt.addInput({
        ...signedPsbt.data.globalMap.unsignedTx.tx.ins[0],
        // ...signedPsbt.data.inputs[0],
        witnessUtxo: signedPsbt.data.inputs[0].witnessUtxo,
        // nonWitnessUtxo: signedPsbt.data.inputs[0].nonWitnessUtxo,
        // finalScriptWitness: signedPsbt.data.inputs[0].finalScriptWitness,
      });

      input++;

      // Add payer output
      psbt.addOutput({
        ...signedPsbt.data.globalMap.unsignedTx.tx.outs[0],
      });

      output++;
    }

    // Add rune output
    psbt.addOutput({
      address: receiverAddress,
      value: this.DUMMY_UTXO_VALUE,
    });

    // Add op_return rune script
    let [block, tx] = rune.split(":")

    let rune_script = encipherSend(block, tx, 0, output);

    console.log(rune_script)

    output++;

    psbt.addOutput({
      script: rune_script,
      value: 0,
    });

    output++;

    // Add payment utxo inputs
    for (const utxo of paymentUtxos) {
      const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));
      try {
        tx.setWitness(parseInt(tx.outs[utxo.vout]), []);
      } catch (e) {
        console.log(e)
      }
      
      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[utxo.vout],
      });

      input++;
      totalValue += utxo.value;
      totalPaymentValue += utxo.value;
    }

    // Service fee
    var market_fee = 0;
    if (this.SERVICE_FEE_ADDRESS != undefined && this.SERVICE_FEE_RATE != undefined) {
      market_fee = Math.round(price * this.SERVICE_FEE_RATE - price);
      console.log("FeeRate: %s, Service_fee: %s", this.SERVICE_FEE_RATE, market_fee);
      if (market_fee > 0) {
        psbt.addOutput({
          address: this.SERVICE_FEE_ADDRESS,
          value: market_fee,
        });
      }
    }

    // Inputs
    console.log(`Inputs: ${input}`)
    console.log(`Outputs: ${output}`)

    const fee = this.calculateFee(
      psbt.txInputs.length,
      psbt.txOutputs.length,
      await this.recommendedFeeRate
    );

    const changeValue = totalValue - price - market_fee - fee;

    if (changeValue < 0) {
      throw `Your wallet address doesn't have enough funds to buy this rune.
        Price:      ${utils.satToBtc(price)} ${this.COIN}
        Fees:       ${utils.satToBtc(fee + market_fee)} ${this.COIN}
        You have:   ${utils.satToBtc(totalPaymentValue)} ${this.COIN}
        Required:   ${utils.satToBtc(totalValue - changeValue)} ${this.COIN}
        Missing:    ${utils.satToBtc(-changeValue)} ${this.COIN}`;
    }

    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: changeValue,
    });

    return psbt.toBase64();
  },

  async generatePSBTMintRune(payerAddress, receiverAddress, rune, payerUtxos, cpfpTxHex = null, cpfpTxId = null) {
    const psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
    let totalValue = 0;

    // console.log(receiverAddress)
    // console.log(rune)
    // console.log(payerUtxos)

    let [block, tx] = rune.split(":")

    let rune_script = encipherMint(block, tx);

    // console.log(rune_script)

    if (cpfpTxHex == null) {
      for (const utxo of payerUtxos) {
        const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));
        try {
          tx.setWitness(parseInt(tx.outs[utxo.vout]), []);
        } catch (e) {
          console.warn(e);
        }

        psbt.addInput({
          hash: utxo.txid,
          index: utxo.vout,
          nonWitnessUtxo: tx.toBuffer(),
          // witnessUtxo: tx.outs[utxo.vout],
        });

        totalValue += utxo.value;
      }
    } else {
      const tx = Transaction.fromHex(cpfpTxHex);
      const refund_vout = tx.outs.length - 1;
      
      try {
        tx.setWitness(parseInt(tx.outs[refund_vout]), []);
      } catch (e) {
        console.warn(e);
      }

      psbt.addInput({
        hash: cpfpTxId,
        index: refund_vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[utxo.vout],
      });

      totalValue += tx.outs[refund_vout].value;
    }

    psbt.addOutput({
      script: rune_script,
      value: 0,
    });

    psbt.addOutput({
      address: receiverAddress,
      value: this.DUMMY_UTXO_VALUE,
    });

    psbt.addOutput({
      address: this.SERVICE_FEE_ADDRESS,
      value: this.MINT_FEE,
    });

    const fee = this.calculateFee(
      psbt.txInputs.length,
      psbt.txOutputs.length,
      await this.recommendedFeeRate
    );

    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: totalValue - this.DUMMY_UTXO_VALUE - this.MINT_FEE - fee,
    });

    return psbt.toBase64();
  },

  async generatePSBTSendRune(payerAddress, receiverAddress, rune, amount, runicUtxos, payerUtxos) {
    const psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
    let totalValue = 0;

    let [block, tx] = rune.split(":")

    let rune_script = encipherSend(block, tx, amount, 2);

    console.log(rune_script)

    for (const utxo of runicUtxos) {
      const key = Object.keys(utxo)[0];

      const [txid, index] = key.split(":");

      const vout = Number(index);

      const tx = Transaction.fromHex(await this.getTxHexById(txid));
      try {
        tx.setWitness(parseInt(tx.outs[vout]), []);
      } catch (e) {
        console.warn(e);
      }

      psbt.addInput({
        hash: txid,
        index: vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[utxo.vout],
      });

      totalValue += utxo[key].value;
    }

    for (const utxo of payerUtxos) {
      const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));
      try {
        tx.setWitness(parseInt(tx.outs[utxo.vout]), []);
      } catch (e) {
        console.warn(e);
      }

      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[utxo.vout],
      });

      totalValue += utxo.value;
    }

    psbt.addOutput({
      script: rune_script,
      value: 0,
    });

    psbt.addOutput({
      address: payerAddress,
      value: this.DUMMY_UTXO_VALUE,
    });

    psbt.addOutput({
      address: receiverAddress,
      value: this.DUMMY_UTXO_VALUE,
    });

    const fee = this.calculateFee(
      psbt.txInputs.length,
      psbt.txOutputs.length,
      await this.recommendedFeeRate
    );

    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: totalValue - this.DUMMY_UTXO_VALUE - this.DUMMY_UTXO_VALUE - fee,
    });

    return psbt.toBase64();
  },

  async generatePSBTGeneratingDummyUtxos(payerAddress, numberOfDummyUtxosToCreate, payerUtxos) {
    const psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
    let totalValue = 0;

    console.log(payerAddress)
    console.log(numberOfDummyUtxosToCreate)
    console.log(payerUtxos)

    for (const utxo of payerUtxos) {
      const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));
      try {
        tx.setWitness(parseInt(tx.outs[utxo.vout]), []);
      } catch (e) {
        console.warn(e);
      }

      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        nonWitnessUtxo: tx.toBuffer(),
        // witnessUtxo: tx.outs[utxo.vout],
      });

      totalValue += utxo.value;
    }

    for (let i = 0; i < numberOfDummyUtxosToCreate; i++) {
      psbt.addOutput({
        address: payerAddress,
        value: this.DUMMY_UTXO_VALUE,
      });
    }

    const fee = this.calculateFee(
      psbt.txInputs.length,
      psbt.txOutputs.length,
      await this.recommendedFeeRate
    );

    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: totalValue - numberOfDummyUtxosToCreate * this.DUMMY_UTXO_VALUE - fee,
    });

    return psbt.toBase64();
  },

  async generatePSBTSendBitcoin(payerAddress, receiverAddress, amount, payerUtxos) {
    const psbt = new Psbt({ network: WalletProvider.LITECOIN_NETWORK });
    let totalValue = 0;

    console.log(payerAddress)
    console.log(receiverAddress)
    console.log(amount)

    for (const utxo of payerUtxos) {
      const tx = Transaction.fromHex(await this.getTxHexById(utxo.txid));

      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        nonWitnessUtxo: tx.toBuffer(),
        witnessUtxo: tx.outs[utxo.vout],
      });

      totalValue += utxo.value;
    }

    psbt.addOutput({
      address: receiverAddress,
      value: amount,
    });

    const fee = this.calculateFee(
      psbt.txInputs.length,
      psbt.txOutputs.length,
      await this.recommendedFeeRate
    );

    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: totalValue - amount - fee,
    });

    return psbt.toBase64();
  },

  async updatePayerAddress(payerAddress, minimumValue = null, spendUnconfirmed = true) {
    let price = this.priceCache;
    if (minimumValue) {
      price = minimumValue;
    }

    try {
      this.payerUtxos = await this.getAddressUtxos(payerAddress);
    } catch (e) {
      return console.error(e);
    }

    console.log(this.payerUtxos);

    if (!spendUnconfirmed) {
      this.payerUtxos = this.payerUtxos.filter(
        (utxo) => utxo.status.confirmed == true
      )
    }

    const potentialDummyUtxos = this.payerUtxos.filter(
      (utxo) => utxo.value <= this.DUMMY_UTXO_VALUE
    );
    this.dummyUtxo = undefined;
    this.dummyUtxos = [];

    for (const potentialDummyUtxo of potentialDummyUtxos) {
      if (!(await this.doesUtxoContainInscription(potentialDummyUtxo))) {
        if (!this.dummyUtxo) {
          this.dummyUtxo = potentialDummyUtxo;
        }
        this.dummyUtxos.push(potentialDummyUtxo);
      }
    }

    console.log(this.dummyUtxos);

    let minimumValueRequired;
    let vins;
    let vouts;

    if (!this.dummyUtxo) {
      minimumValueRequired = this.NUMBER_DUMMIES_REQUIRED * this.DUMMY_UTXO_VALUE;
      vins = 0;
      vouts = this.NUMBER_DUMMIES_REQUIRED;
    } else {
      minimumValueRequired =
        price + this.NUMBER_DUMMIES_REQUIRED * this.DUMMY_UTXO_VALUE;
      vins = 1;
      vouts = 2 + this.NUMBER_DUMMIES_REQUIRED;
    }

    if (minimumValue) {
      minimumValueRequired = Math.max(minimumValue, minimumValueRequired);
    }

    try {
      this.paymentUtxos = await this.selectUtxos(
        this.payerUtxos,
        minimumValueRequired,
        vins,
        vouts,
        await this.recommendedFeeRate
      );
    } catch (e) {
      this.paymentUtxos = undefined;
      console.error(e);
      return alert(e);
    }
  },

  calculateFee(vins, vouts, recommendedFeeRate, includeChangeOutput = true) {
    const baseTxSize = 10;
    const inSize = 180;
    const outSize = 34;

    const txSize =
      baseTxSize +
      vins * inSize +
      vouts * outSize +
      includeChangeOutput * outSize;
    const fee = txSize * recommendedFeeRate;

    return fee;
  },

  validateSellerPSBTAndExtractPrice(sellerSignedPsbtBase64, utxo, extract = true) {
    this.sellerSignedPsbt = Psbt.fromBase64(sellerSignedPsbtBase64, {
      network: WalletProvider.LITECOIN_NETWORK,
    });
    const sellerInput = this.sellerSignedPsbt.txInputs[0];
    const sellerSignedPsbtInput = `${sellerInput.hash
      .reverse()
      .toString("hex")}:${sellerInput.index}`;

    if (sellerSignedPsbtInput != utxo) {
      throw `Seller signed PSBT does not match this inscription\n\n${sellerSignedPsbtInput}\n!=\n${utxo}`;
    }

    if (
      this.sellerSignedPsbt.txInputs.length != 1 ||
      this.sellerSignedPsbt.txInputs.length != 1
    ) {
      throw `Invalid seller signed PSBT`;
    }

    if (extract) {
      try {
        this.sellerSignedPsbt.extractTransaction(true);
      } catch (e) {
        if (e.message == "Not finalized") {
          throw "PSBT not signed";
        } else if (e.message != "Outputs are spending more than Inputs") {
          throw "Invalid PSBT " + e.message || e;
        }
      }
    }

    const sellerOutput = this.sellerSignedPsbt.txOutputs[0];
    this.priceCache = sellerOutput.value;

    return Number(this.priceCache);
  },

  mergeSignedBuyingPSBTBase64(signedListingsPSBTBase64, signedBuyingPSBTBase64, noOffset = false) {
    const buyerSignedPsbt = Psbt.fromBase64(signedBuyingPSBTBase64, {
      network: WalletProvider.LITECOIN_NETWORK,
    });

    let offset = signedListingsPSBTBase64.length - 1;

    for (let index = 0; index < signedListingsPSBTBase64.length; index++) {
      const sellerSignedPsbt = Psbt.fromBase64(signedListingsPSBTBase64[index], {
        network: WalletProvider.LITECOIN_NETWORK,
      });

      let offsetInput = noOffset ? index : this.BUYING_PSBT_SELLER_SIGNATURE_INDEX + offset + index;

      buyerSignedPsbt.data.globalMap.unsignedTx.tx.ins[offsetInput] = sellerSignedPsbt.data.globalMap.unsignedTx.tx.ins[0];
      buyerSignedPsbt.data.inputs[offsetInput] = sellerSignedPsbt.data.inputs[0];
    }

    return buyerSignedPsbt.toBase64();
  }
};