<template>
  <div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <h2 style="margin-bottom: 10;">Collections</h2>
      </div>
      <div v-if="sales_24h >= 5" class="hot-badge">🔥 {{ sales_24h }} buys in 24 hours
        <tippy to="parent" placement="bottom">Across all markets</tippy>
      </div>
    </div>
  </div>
  <div class="collections">
    <div>
      <router-link
        v-for="(collection, index) in firstHalfCollections"
        :key="index"
        :to="{ name: 'collection', params: { name: collection.collection }}"
        class="collection-item">
        <div class="collection-rank">{{ index + 1 }}</div>
        <img class="collection-image" loading="lazy" :src="collection.image">
        <div>
          <div class="collection-name">{{ collection.name }}</div>
          <div class="collection-floor">
            Floor price: <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(collection.floor_price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}
          </div>
        </div>
        <div class="collection-volume">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon">{{ satToBtc(collection.total_volume).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}
          <tippy to="parent" placement="bottom">Total Volume</tippy>
        </div>
      </router-link>
    </div>
    <div>
      <router-link
        v-for="(collection, index) in lastHalfCollections"
        :key="index"
        :to="{ name: 'collection', params: { name: collection.collection }}"
        class="collection-item">
        <div class="collection-rank">{{ index + Math.ceil(this.collections.length / 2 + 1) }}</div>
        <img v-if="collection.image.length > 0" class="collection-image" loading="lazy" :src="collection.image">
        <div v-if="collection.image.length == 0" class="collection-image">{{ collection.name }}</div>
        <div>
          <div class="collection-name">{{ collection.name }}</div>
          <div class="collection-floor">
            Floor price: <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(collection.floor_price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}
          </div>
        </div>
        <div class="collection-volume">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon">{{ satToBtc(collection.total_volume).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}
          <tippy to="parent" placement="bottom">Total Volume</tippy>
        </div>
      </router-link>
      <router-link :to="{ name: 'collections' }" class="show-all-card show-all-collections">
        <div style="display: flex; align-items: center; height: 4rem;">Show all collections</div>
      </router-link>
    </div>
  </div>
  <h2>Runes</h2>
  <div class="table-container">
    <RunesTableComponent :items="runes"/>
  </div>
  <div style="display: flex; justify-content: center; align-items: center; margin-top: 1rem;">
    <router-link :to="{ name: 'runes-list', params: null }" class="show-all-card show-all-collections" style="width: 100%;">Show all Runes</router-link>
  </div>
  <h2>LTC-20</h2>
  <div class="table-container">
    <Ltc20TableComponent :items="ltc20"/>
  </div>
  <div style="display: flex; justify-content: center; align-items: center; margin-top: 1rem;">
    <router-link :to="{ name: 'ltc20s' }" class="show-all-card show-all-collections" style="width: 100%;">Show all LTC-20 tokens</router-link>
  </div>
  <div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <h2 style="margin-bottom: 0;">Litemaps</h2>
        <div>Floor price: <img src="@/assets/Litecoin.png" class="litecoin-icon"><span>{{ (litemaps[0]) ? satToBtc(litemaps[0].price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) : 0 }}</span></div>
      </div>
    </div>
  </div>
  <div class="cards-wrapper" style="margin: 1rem 0;">
    <div class="cards">
      <div v-for="item in litemaps" :key="item.inscription_id" class="listing">
        <div class="listing-item">
          <!-- <img class="inscription-preview" src="https://ordinalslite.com/content/ebc07ae334fbd2753a32550dd28341af90c2219885b401a529307ed8f093fd17i0"> -->
          {{ item.domain }}</div>
        <div class="listing-info">
          <span>Seller:</span>
          <span>
            <router-link :to="{ name: 'address', params: { address: item.seller_address }}">{{ short_address(item.seller_address) }}</router-link>
          </span>
        </div>
        <div class="listing-price">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>{{ satToBtc(item.price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}
          <span class="usd-value">(${{ (satToBtc(item.price) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }})</span>
        </div>
        <button class="buy-button" @click="buyItem(item.inscription_id)">Buy</button>
      </div>
      <router-link :to="{ name: 'litemaps' }" class="show-all-card show-all-litemaps">
        <div >Show all listed litemaps</div>
      </router-link>
    </div>
  </div>
  <div v-if="sales_biggest.length > 0">
    <h2>Largest Daily Sales</h2>
    <div class="scrollable-wrapper biggest-sales" style="margin-top: 1rem;">
      <vue-horizontal>
        <div v-for="item in sales_biggest" :key="item.inscription_id" class="listing scrollable-item">
          <router-link :to="{ name: 'inscription', params: { id: item.inscription_id }}">
            <img v-if="item.type == 0" class="inscription-img" loading="lazy" :src="`https://ordinalslite.com/content/${item.inscription_id}`">
            <div v-if="item.type == 1" class="inscription-img inscription-iframe" style="height: auto;">
              <iframe scrolling="no" loading="lazy" style="transform: scale(0.5); transform-origin: 0 0; width: 200%; height: 500%; border: 0;" :src="`https://ordinalslite.com/preview/${item.inscription_id}`"></iframe>
            </div>
            <div class="inscription-number">#{{ item.inscription_number }}</div>
          </router-link>
          <div style="padding: 0.25rem 0.75rem 0.75rem; height: 100%; display: flex; flex-direction: column;">
            <div class="listing-item-nft">
              <!-- <div>{{ item.collection_name}}</div> -->
              <div style="text-align: center;">{{ item.name }}</div>
            </div>
            <div class="price-info nft">
              <div class="price-info-val">
                <div class="val">
                  <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(item.price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }} <span class="usd-value">(${{ (satToBtc(item.price) * this.usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }})</span>
                </div>
              </div>
            </div>
            <button class="buy-item" disabled="">Sold</button>
          </div>
        </div>
      </vue-horizontal>
      <div class="icon-wrapper" style="left: -0.5rem;">
        <div class="scroll-icon">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </div>
      </div>
      <div class="icon-wrapper" style="right: -0.5rem;">
        <div class="scroll-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
      </div>
    </div>
  </div>
  <div>
    <h2>Recent Sales</h2>
    <div class="scrollable-wrapper biggest-sales" style="margin-top: 1rem;">
      <vue-horizontal>
        <div v-for="item in sales" :key="item.inscription_id" class="listing scrollable-item">
          <router-link :to="{ name: 'inscription', params: { id: item.inscription_id }}">
            <img v-if="item.type == 0" class="inscription-img" loading="lazy" :src="`https://ordinalslite.com/content/${item.inscription_id}`">
            <div v-if="item.type == 1" class="inscription-img inscription-iframe" style="height: auto;">
              <iframe scrolling="no" loading="lazy" style="transform: scale(0.5); transform-origin: 0 0; width: 200%; height: 500%; border: 0;" :src="`https://ordinalslite.com/preview/${item.inscription_id}`"></iframe>
            </div>
            <div class="inscription-number">#{{ item.inscription_number }}</div>
          </router-link>
          <div style="padding: 0.25rem 0.75rem 0.75rem; height: 100%; display: flex; flex-direction: column;">
            <div class="listing-item-nft">
              <!-- <div>{{ item.collection_name}}</div> -->
              <div style="text-align: center;">{{ item.name }}</div>
            </div>
            <div class="price-info nft">
              <div class="price-info-val">
                <div class="val">
                  <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(item.price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }} <span class="usd-value">(${{ (satToBtc(item.price) * this.usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }})</span>
                </div>
              </div>
            </div>
            <button class="buy-item" disabled>Sold</button>
          </div>
        </div>
      </vue-horizontal>
      <div class="icon-wrapper" style="left: -0.5rem;">
        <div class="scroll-icon">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </div>
      </div>
      <div class="icon-wrapper" style="right: -0.5rem;">
        <div class="scroll-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js';

import VueHorizontal from 'vue-horizontal';
import Ltc20TableComponent from '@/components/Ltc20TableComponent.vue';
import RunesTableComponent from '@/components/RunesTableComponent.vue';

export default {
  data() {
    return {
      isModalVisible: false,
      usd_rate: inject('usd_rate', 0),
      litemaps: [],
      litemaps_total: 0,
      collections: [],
      ltc20: [],
      runes: [],
      items: [],
      sales: [],
      sales_biggest: [],
      sales_24h: 0,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const [
      litemaps_resp,
      collections_resp,
      tokens_resp,
      runes_resp,
      sales_resp
    ] = await Promise.all([
      fetch(`${config.apiUrl}/listings/litemaps?size=9`).then(d => d.json()),
      fetch(`${config.apiUrl}/collections`).then(d => d.json()),
      fetch(`${config.apiUrl}/ltc20?sort=transactions`).then(d => d.json()),
      fetch(`${config.apiUrl}/runes`).then(d => d.json()),
      fetch(`${config.apiUrl}/market/biggestSales`).then(d => d.json())
    ]);
    next(vm => {
      vm.litemaps = litemaps_resp.result.items;
      var sorted_collection = collections_resp.result.sort((a, b) => b.total_volume - a.total_volume).slice(0, 11);
      vm.collections = sorted_collection;
      var sorted_runes = runes_resp.result.slice(0, 5);
      var sorted_ltc20 = tokens_resp.result.sort((a, b) => b.total_volume - a.total_volume).slice(0, 5);
      vm.ltc20 = sorted_ltc20;
      vm.runes = sorted_runes;
      vm.sales = sales_resp.result.sales;
      vm.sales_biggest = sales_resp.result.sales_biggest;
      vm.sales_24h = sales_resp.result.sales_24h_total;
    });
  },
  components: {
    VueHorizontal,
    Ltc20TableComponent,
    RunesTableComponent
  },
  methods: {
    short_address(str) {
      return str.substr(0, 5) + '...' + str.substr(str.length-5, str.length);
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
    buyItem(itemId) {
      console.log(`Buying item with ID ${itemId}`);
      this.emitter.emit("modal-buy-open", { listings: [itemId] });
    },
  },
  computed: {
    firstHalfCollections() {
      return this.collections.slice(0, Math.ceil(this.collections.length / 2));
    },
    lastHalfCollections() {
      return this.collections.slice(Math.ceil(this.collections.length / 2));
    },
  },
};
</script>

<style scoped>
.details {
  margin-bottom: 2rem;
}

.details>div {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3rem;
}

.detail-item {
  margin-bottom: 0.5rem;
  margin-left: 3rem;
}

.detail-value {
  font-weight: 700;
}

.detail-text {
  font-size: 90%;
  line-height: 1;
  color: #fffc;
}

.collections {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4rem;
}

.collections>div {
  flex-grow: 1;
}

.collection-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0.75rem 1rem;
  color: #fff;
  border-radius: 12px;
  transition: all .15s ease-in-out;
}

.collection-item:hover {
  background-color: #4c505c33;
}

.collection-rank {
  font-size: 1.2rem;
  font-weight: 700;
}

.collection-image {
  display: flex;
  width: 4rem;
  height: 4rem;
  margin: 0 1.5rem;
  border-radius: 50%;
  background-color: #212121;
  object-fit: cover;
  flex-shrink: 0;
  image-rendering: pixelated;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
}

.collection-name {
  font-size: 1.2rem;
  font-weight: 600;
}

.collection-floor {
  font-size: .9rem;
  color: #fffc;
}

.collection-volume {
  margin-left: auto;
  padding-left: 1.5rem;
}

.show-all-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 700;
  color: #D4D6E1;
}

.show-all-card:hover {
  color: #345D9D;
}

.show-all-litemaps {
  background-color: #4c505c33;
  border: 1px solid transparent;
  transition: all .25s ease-in-out;
}

.show-all-litemaps>div {
  text-align: center;
  padding: 0.75rem;
}

.show-all-litemaps:hover, .show-all-collections:hover {
  border-color: #345D9D;
}

.show-all-collections, .show-all-tokens {
  transition: all .15s ease-in-out;
}

.show-all-collections:hover {
  background-color: #4c505c33;
}

.show-all-collections {
  padding: 0.75rem 1rem;
}

.inscription-iframe {
  height: 8rem;
  overflow: hidden;
}

.biggest-sales .scrollable {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.biggest-sales .scrollable>* {
  min-width: 14rem;
}

.scrollable-item {
  width: 14rem;
}

.icon-wrapper {
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  align-items: center;
  border-radius: 50%;
  color: #fff;
}

.scroll-icon {
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff3;
  padding: 0.5rem;
}

.val {
  flex: 1;
  text-align: center;
}

.biggest-sales .listing {
  padding: 0;
  margin: 0 0.5rem;
}

.listing-item-nft {
  display: flex;
  justify-content: center;
  gap: 0.4rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-direction: column;
  align-items: center;
}

.price-info.nft {
  margin-top: auto;
  padding: 0.4rem 0;
}

.buy-button {
  height: 30px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.buy-item:disabled {
  background-color: #ffffff12;
  color: #cacaca;
}
</style>