import { WalletProvider } from './web3';
import { encode } from 'varuint-bitcoin';
import { Transaction, Psbt, address as PsbtAddress, script, crypto } from 'bitcoinjs-lib';
import { ECPair } from './signer';

function bip0322Hash(message) {
  const { sha256 } = crypto;
  const tag = 'BIP0322-signed-message';
  const tagHash = sha256(Buffer.from(tag));
  const result = sha256(Buffer.concat([tagHash, tagHash, Buffer.from(message)]));
  return result.toString('hex');
}

// Convert bitcoin address to scriptPk
export function addressToScriptPk(address) {
  return PsbtAddress.toOutputScript(address, WalletProvider.LITECOIN_NETWORK);
}

// ECDSA signature validator
function ecdsaValidator(pubkey, msghash, signature) {
  return ECPair.fromPublicKey(pubkey).verify(msghash, signature);
}

// Schnorr signature validator
function schnorrValidator(pubkey, msghash, signature) {
  return ECPair.fromPublicKey(pubkey).verifySchnorr(msghash, signature);
}

function generatePSBTBIP322Simple(message, address) {
  const outputScript = addressToScriptPk(address);

  const prevoutHash = Buffer.from('0000000000000000000000000000000000000000000000000000000000000000', 'hex');
  const prevoutIndex = 0xffffffff;
  const sequence = 0;
  const scriptSig = Buffer.concat([Buffer.from('0020', 'hex'), Buffer.from(bip0322Hash(message), 'hex')]);

  const txToSpend = new Transaction();
  txToSpend.version = 0;
  txToSpend.addInput(prevoutHash, prevoutIndex, sequence, scriptSig);
  txToSpend.addOutput(outputScript, 0);

  const psbtToSign = new Psbt();
  psbtToSign.setVersion(0);
  psbtToSign.addInput({
    hash: txToSpend.getHash(),
    index: 0,
    sequence: 0,
    witnessUtxo: {
      script: outputScript,
      value: 0
    }
  });
  psbtToSign.addOutput({ script: Buffer.from('6a', 'hex'), value: 0 });

  return psbtToSign;
}

function getSignatureFromPsbtOfBIP322Simple(psbt) {
  const txToSign = psbt.extractTransaction();

  function encodeVarString(b) {
    return Buffer.concat([encode(b.byteLength).buffer, b]);
  }

  const len = encode(txToSign.ins[0].witness.length);

  const result = Buffer.concat([len.buffer, ...txToSign.ins[0].witness.map((w) => encodeVarString(w))]);
  const signature = result.toString('base64');

  return signature;
}

export async function signMessage(message, wallet) {
  const psbtToSign = generatePSBTBIP322Simple(message, wallet.getWalletAddress());

  const signedPsbtHex = await wallet.signPsbt(psbtToSign.toBase64());

  const signedPsbt = Psbt.fromHex(signedPsbtHex, WalletProvider.LITECOIN_NETWORK);

  return getSignatureFromPsbtOfBIP322Simple(signedPsbt);
}

export function verifyMessage(message, signature, address) {
  if (address.startsWith('ltc1q')) {
    return verifySignatureP2PWPK(message, signature, address);
  } else if (address.startsWith('ltc1p')) {
    return verifySignatureP2TR(message, address, signature);
  }
  return false;
}

function verifySignatureP2PWPK(message, signature, address) {
  console.log(message, signature, address);

  const outputScript = PsbtAddress.toOutputScript(address, WalletProvider.LITECOIN_NETWORK);

  const prevoutHash = Buffer.from('0000000000000000000000000000000000000000000000000000000000000000', 'hex');
  const prevoutIndex = 0xffffffff;
  const sequence = 0;
  const scriptSig = Buffer.concat([Buffer.from('0020', 'hex'), Buffer.from(bip0322Hash(message), 'hex')]);

  const txToSpend = new Transaction();
  txToSpend.version = 0;
  txToSpend.addInput(prevoutHash, prevoutIndex, sequence, scriptSig);
  txToSpend.addOutput(outputScript, 0);

  const data = Buffer.from(signature, 'base64');
  const _res = script.decompile(data.slice(1));

  const psbtToSign = new Psbt();
  psbtToSign.setVersion(0);
  psbtToSign.addInput({
    hash: txToSpend.getHash(),
    index: 0,
    sequence: 0,
    witnessUtxo: {
      script: outputScript,
      value: 0
    }
  });
  psbtToSign.addOutput({ script: Buffer.from('6a', 'hex'), value: 0 });

  psbtToSign.updateInput(0, {
    partialSig: [
      {
        pubkey: _res[1],
        signature: _res[0]
      }
    ]
  });

  const valid = psbtToSign.validateSignaturesOfAllInputs(ecdsaValidator);
  return valid;
}

function verifySignatureP2TR(message, address, signature) {
  const outputScript = PsbtAddress.toOutputScript(address, WalletProvider.LITECOIN_NETWORK);
  const prevoutHash = Buffer.from('0000000000000000000000000000000000000000000000000000000000000000', 'hex');
  const prevoutIndex = 0xffffffff;
  const sequence = 0;
  const scriptSig = Buffer.concat([Buffer.from('0020', 'hex'), Buffer.from(bip0322Hash(message), 'hex')]);

  const txToSpend = new Transaction();
  txToSpend.version = 0;
  txToSpend.addInput(prevoutHash, prevoutIndex, sequence, scriptSig);
  txToSpend.addOutput(outputScript, 0);

  const data = Buffer.from(signature, 'base64');
  const _res = script.decompile(data.slice(1));
  const sig = _res[0];
  const pubkey = Buffer.from('02' + outputScript.subarray(2).toString('hex'), 'hex');

  const psbtToSign = new Psbt();
  psbtToSign.setVersion(0);
  psbtToSign.addInput({
    hash: txToSpend.getHash(),
    index: 0,
    sequence: 0,
    witnessUtxo: {
      script: outputScript,
      value: 0
    }
  });
  psbtToSign.addOutput({ script: Buffer.from('6a', 'hex'), value: 0 });
  const tapKeyHash = (psbtToSign).__CACHE.__TX.hashForWitnessV1(0, [outputScript], [0], 0);
  const valid = schnorrValidator(pubkey, tapKeyHash, sig);
  return valid;
}