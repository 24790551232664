<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot name="header">
            Mint Rune
          </slot>
        </h1>
  
        <div class="modal-body">
          <slot name="body">
            <div class="body-items">
              <!-- placeholder -->
              <div v-if="!rune" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-if="rune" class="body-item">
                <div>
                  <img v-if="!runeImageError && rune.parent" class="inscription-preview" :src="`https://ordinalslite.com/content/${rune.parent}`" @error="handleImageError()">
                  <img v-else-if="!rune.parent" class="inscription-preview" src="@/assets/rune.png">
                  <div v-else class="inscription-preview" style="font-size: 5rem; text-align: center;">
                    {{ rune.symbol }}
                  </div>
                </div>
                <div>{{ rune.id }}</div>
              </div>
            </div>
            <div class="body-grid">
              <div class="modal-row">
                <div>Rune:</div>
                <div class="modal-row-value">
                  <span v-if="rune">{{ rune.spaced_rune }}</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Amount:</div>
                <div class="modal-row-value">
                  <span v-if="rune">{{ rune.terms ? rune.terms.amount / Math.pow(10, rune.divisibility) : '-' }}</span>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin: 1rem 0px;">
              <div style="font-size: 1rem; font-weight: 600; margin-right: 1rem; white-space: nowrap;">Receive Address:</div>
              <input v-model="receiver_address" class="modal-input" type="text" placeholder="LTC Address" style="width: 100%; max-width: 24rem;">
            </div>
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin: 1rem 0px;">
              <div style="font-size: 1rem; font-weight: 600; margin-right: 1rem; white-space: nowrap;">Repeat Mint:</div>
              <div class="modal-range-display">{{ mint_repeat }}</div>
              <input v-model="mint_repeat" class="modal-input" type="range" style="width: 100%; max-width: 24rem; margin-left: 1rem;" min="1" max="20" step="1">
            </div>
            <div class="body-grid">
              <div class="modal-row">
                <div>Service Fee:</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ (satToBtc(mint_fee * mint_repeat)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(mint_fee * mint_repeat) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Network Fee:</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  ≈ {{ satToBtc(network_fee * mint_repeat) }}
                  <span class="usd-value">(${{ (satToBtc(network_fee * mint_repeat) * usd_rate).toFixed(2) }})</span>
                </div>
              </div>
              <div class="modal-row modal-total">
                <div>Total:</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ satToBtc(mint_fee * mint_repeat).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(mint_fee * mint_repeat) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Available Balance:</div>
                <div class="modal-row-value" :style="{ color: wallet_connected && available_balance < mint_fee * mint_repeat ? 'red' : 'inherit' }">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ satToBtc(available_balance).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(available_balance) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div v-if="rune && !rune.mintable" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">Rune not mintable</div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          :disabled="!wallet_connected || !isReady || (rune && !rune.mintable) || !isValidAddress(receiver_address) || isMinting"
          @click="mintRune"
        >
          <font-awesome-icon v-if="isMinting" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
          {{ !wallet_connected ? 'Connect wallet to Mint' : isValidAddress(receiver_address) >= 1 ? (isMinting ? `Minting Rune` : `Mint`) : 'Enter an address' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from "vue-toastification";
import config from '@/config.js'
import utils from '@/utils.js'

export default {
  name: 'ModalComponent',
  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      isMinting: false,
      isEdit: false,
      isToken: false,
      runeImageError: false,
      errorMessage: null,
      rune: {},
      rune_id: null,
      receiver_address: null,
      mint_repeat: 1,
      order_address: null,
      content: null,
      content_type: null,
      network_fee: 500,
      mint_fee: config.mintFee,
      order_response: {},
      usd_rate: inject('usd_rate', 0),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
      wallet_provider: this.$walletProvider,
    };
  },
  methods: {
    close() {
      this.isModalVisible = false;
      this.isMinting = false;
      this.errorMessage = null;
    },
    async mintRune() {
      if (!this.isReady) return;

      this.isMinting = true;
      this.errorMessage = null;
      
      var repeats_broadcast = 0;

      await this.wallet_provider.updatePayerAddress(this.wallet_address, this.mint_fee * this.mint_repeat, false);

      var utxos = this.wallet_provider.paymentUtxos;

      var parent_tx_hex = null;
      var parent_tx_id = null;

      for (var i = 0; i < this.mint_repeat; i++) {
        var unsigned_mint_psbt = await this.wallet_provider.generatePSBTMintRune(this.wallet_address, this.receiver_address, this.rune.id, utxos, parent_tx_hex, parent_tx_id);

        // console.log(unsigned_mint_psbt);

        try {
          var signed_psbt_hex = await this.wallet_provider.signPsbt(unsigned_mint_psbt);
          var signed_tx_hex = this.wallet_provider.psbtBase64ToTransactionHex(utils.hexToBase64(signed_psbt_hex));
          var txid = await this.wallet_provider.broadcastTx(signed_tx_hex);
          console.log(txid);

          parent_tx_hex = signed_tx_hex;
          parent_tx_id = txid;
        } catch (e) {
          this.isMinting = false;
          this.errorMessage = e;
          console.error(e);
          if (repeats_broadcast > 0) {
            this.toast.info(`Runes partially minted ${repeats_broadcast}/${this.mint_repeat}`, {
              position: "bottom-right"});
          }
          return;
        }
        
        repeats_broadcast = repeats_broadcast + 1;
      }

      this.toast.success(`Runes minting ${repeats_broadcast}/${this.mint_repeat}`, {
        position: "bottom-right"});
      this.isMinting = false;
      this.isModalVisible = false;
    },
    handleImageError() {
      this.runeImageError = true;
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    isIframe(contentType) {
      if (contentType) {
        return contentType.startsWith("text/html");
      }
      return false;
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("modal-mint-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.isMinting = false;
      this.errorMessage = null;
      this.content = null;
      this.rune = null;
      this.rune_id = parameters.rune_id;
      this.receive_address = this.wallet_address;
      fetch(`${config.apiUrl}/runes/${this.rune_id}`)
      .then(d => d.json())
      .then(j => {
        if (j.status == 1) {
          this.rune = j.result;
          this.isReady = true;
        }
      })
    });
  }
};
</script>

<style scoped>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .modal-input {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    width: 6rem;
    text-align: left;
    font-size: inherit;
  }

  .modal-row.modal-input {
    padding: 0.1rem;
  }

  .modal-input:focus {
    border-bottom: 1px solid #007aff;
  }

  .modal-input:enabled {
    border-bottom: 1px solid tan;
  }

  .modal-range-display {
    padding: 0.5rem 0.5rem;
    background-color: #22222280;
    border-radius: 5px;
  }

  .modal-amount {
    margin-top: 1em;
    font-size: larger;
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
  }

  .inscription-iframe {
    height: 8rem;
    overflow: hidden;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }
</style>